import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashIndex {
  REVENUE = 'REVENUE',
  ROOM_NIGHT = 'ROOM_NIGHT',
  DAILY_AVERAGE = 'DAILY_AVERAGE',
  OCCUPATION = 'OCCUPATION',
  REV_PAR = 'REV_PAR',
  RESERVATIONS = 'RESERVATIONS',
  PAX = 'PAX',
  PAX_CHD = 'PAX_CHD',
  LOS = 'LOS',
  REVENUE_FB = 'REVENUE_FB',
  REVENUE_DAILY = 'REVENUE_DAILY',
  FEE = 'FEE',
}

export function getDashIndexHumanRead(index: DashIndex) {
  switch (index) {
    case DashIndex.REVENUE:
      return 'Receita';
    case DashIndex.ROOM_NIGHT:
      return 'RN';
    case DashIndex.DAILY_AVERAGE:
      return 'DM';
    case DashIndex.REV_PAR:
      return 'RevPar';
    case DashIndex.OCCUPATION:
      return 'OCC';
    case DashIndex.PAX:
      return 'Pax';
    case DashIndex.PAX_CHD:
      return 'Child';
    case DashIndex.RESERVATIONS:
      return 'Res.';
    case DashIndex.LOS:
      return 'LOS';
    case DashIndex.REVENUE_FB:
      return 'Receita A&B';
    case DashIndex.REVENUE_DAILY:
      return 'Receita diárias';
    case DashIndex.FEE:
      return 'Taxas'; // Ex, taxa de iss, taxa de turismo
    default:
      return index;
  }
}

export const allDashIndexes: GenericIdName[] = Object.values(DashIndex).map((x) => ({
  _id: x,
  name: getDashIndexHumanRead(x),
}));

export function dashIndexIsAverage(index: DashIndex) {
  return (
    index === DashIndex.DAILY_AVERAGE ||
    index === DashIndex.OCCUPATION ||
    index === DashIndex.REV_PAR ||
    index === DashIndex.LOS
  );
}

export function getDashIndexFieldName(index: DashIndex) {
  switch (index) {
    case DashIndex.REVENUE:
      return 'revenue';
    case DashIndex.REVENUE_FB:
      return 'revenueFoodAndBeverage';
    case DashIndex.REVENUE_DAILY:
      return 'revenueDaily';
    case DashIndex.FEE:
      return 'feeAmount';
    case DashIndex.ROOM_NIGHT:
      return 'roomNight';
    case DashIndex.DAILY_AVERAGE:
      return 'dailyAverage';
    case DashIndex.REV_PAR:
      return 'revPar';
    case DashIndex.OCCUPATION:
      return 'occupation';
    case DashIndex.PAX:
      return 'pax';
    case DashIndex.PAX_CHD:
      return 'paxChd';
    case DashIndex.RESERVATIONS:
      return 'reservations';
    case DashIndex.LOS:
      return 'los';
    default:
      throw new Error('Invalid index: ' + index);
  }
}

export const allPickUpDashIndexes = Object.values(DashIndex);

/**
 * @description return the icon name for the dash index (iconify string)
 */
export function getDashIndexIcon(index: DashIndex) {
  switch (index) {
    case DashIndex.REVENUE:
      return 'mdi:currency-usd';
    case DashIndex.REVENUE_FB:
      return 'mdi:food';
    case DashIndex.REVENUE_DAILY:
      return 'mdi:calendar';
    case DashIndex.FEE:
      return 'mdi:cash-register';
    case DashIndex.ROOM_NIGHT:
      return 'mdi:bed';
    case DashIndex.DAILY_AVERAGE:
      return 'mdi:chart-line';
    case DashIndex.REV_PAR:
      return 'mdi:chart-bar';
    case DashIndex.OCCUPATION:
      return 'mdi:account-group';
    case DashIndex.PAX:
      return 'mdi:account';
    case DashIndex.PAX_CHD:
      return 'mdi:account-child';
    case DashIndex.RESERVATIONS:
      return 'mdi:calendar-check';
    case DashIndex.LOS:
      return 'mdi:clock';
    default:
      return 'mdi:money';
  }
}

/**
 * @description return the index color for the dash index
 */
export function getDashIndexColor(index: DashIndex): string {
  switch (index) {
    case DashIndex.REVENUE:
      return '#FF5733'; // Laranja avermelhado
    case DashIndex.REVENUE_FB:
      return '#33FF57'; // Verde vibrante
    case DashIndex.REVENUE_DAILY:
      return '#3357FF'; // Azul vibrante
    case DashIndex.FEE:
      return '#FF33A1'; // Rosa
    case DashIndex.ROOM_NIGHT:
      return '#FFC300'; // Amarelo vibrante
    case DashIndex.DAILY_AVERAGE:
      return '#581845'; // Roxo escuro
    case DashIndex.REV_PAR:
      return '#900C3F'; // Vermelho vinho
    case DashIndex.OCCUPATION:
      return '#DAF7A6'; // Verde claro
    case DashIndex.PAX:
      return '#C70039'; // Vermelho intenso
    case DashIndex.PAX_CHD:
      return '#900C3F'; // Vermelho vinho
    case DashIndex.RESERVATIONS:
      return '#1C2833'; // Cinza escuro
    case DashIndex.LOS:
      return '#FFC0CB'; // Rosa claro
    default:
      return '#000000'; // Preto como padrão
  }
}
