import { GenericIdName } from '@/types/models/generic-id-name';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

/**
 * Componente Select desacoplado do React Hook Form
 */

export interface SelectStaticProps<T extends GenericIdName> {
  label: string;
  options: T[];
  value: T | null;
  onChangeValue: (value: T | null) => void;
  placeholder?: string;
  size?: 'medium' | 'small';
  disabled?: boolean;
  helperText?: string;
  loading?: boolean;
  error?: string;
  disableClearable?: boolean;
  searchable?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectStatic<T extends GenericIdName>({
  label,
  options = [],
  value,
  onChangeValue,
  placeholder = '',
  size = 'medium',
  disabled = false,
  helperText,
  loading = false,
  error = '',
  disableClearable = false,
  searchable = true,
}: SelectStaticProps<T>) {
  const [open, setOpen] = useState(false);

  const _disabled = loading || disabled;

  const handleChangeSingleSelect = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    onChangeValue(options.find((x) => x._id === value) || null);
  };

  if (!searchable) {
    return (
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          disabled={_disabled}
          labelId="select-label"
          value={value?._id || ''}
          onChange={handleChangeSingleSelect}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
          fullWidth
          error={!!error}
          size={size}
        >
          {!disableClearable ? (
            <MenuItem value="">
              <em>Limpar seleção</em>
            </MenuItem>
          ) : null}
          {options.map((x) => (
            <MenuItem key={x._id} value={x._id}>
              <ListItemText primary={x.name} />
            </MenuItem>
          ))}
        </Select>
        {!!helperText || !!error ? (
          <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }

  return (
    <Autocomplete
      fullWidth
      noOptionsText={'Nenhum resultado.'}
      loadingText="Buscando..."
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={_disabled}
      options={options}
      onChange={(e, val) => onChangeValue(val as any)}
      size={size}
      value={value}
      loading={loading}
      disableClearable={disableClearable}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          helperText={error || helperText}
          error={!!error}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : disableClearable ? null : (
              <div onClick={() => onChangeValue('' as any)}>{params.InputProps.endAdornment}</div>
            ),
          }}
        />
      )}
    />
  );
}
