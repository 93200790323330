import Iconify from '@/components/Iconify';
import { CONCURRENCY_ICON } from '@/config/icons';

import { Fluctuation } from '@/types/models/fluctuation';
import { formatDate } from '@/utils/dates';
import { Avatar, Card, CardContent, CardHeader, Paper } from '@mui/material';

interface Props {
  fluctuation: Fluctuation;
}

export default function FluctuationConcurrencyContainer({ fluctuation }: Props) {
  // TODO: refatorar com novas models de concorrencia
  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary' }} aria-label="recipe">
            <Iconify icon={CONCURRENCY_ICON} width={30} height={30} />
          </Avatar>
        }
        title="Concorrência - Em manutenção"
        subheader={`Análise para o dia ${formatDate(fluctuation.date, 'dd/MM/yyyy')} (Booking.com)`}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 2 }}
      />
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          {/* <ConcurrencyDateDetailsContainer dateSelected={new Date(fluctuation.date)} /> */}
        </Paper>
      </CardContent>
    </Card>
  );
}
