import { RootState } from '@/redux';
import { PushNotification } from '@/types/models/push-notification';
import { apiV1CrudsService } from '@/utils/api-v1';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface NotificationSlice {
  latestNotifications: PushNotification[];
  loadingLatestNotifications: boolean;
  notificationIdOpened?: string; // Abre modal (abre tanto por _id quanto por correlationId/usuario clicando na notificação recém recebida)
}

const initialState: NotificationSlice = {
  latestNotifications: [],
  loadingLatestNotifications: false,
  notificationIdOpened: undefined,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleNotificationIdOpened(state, action: PayloadAction<string | undefined>) {
      state.notificationIdOpened = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestNotifications.pending, (state) => {
        state.loadingLatestNotifications = true;
      })
      .addCase(
        fetchLatestNotifications.fulfilled,
        (state, action: PayloadAction<PushNotification[]>) => {
          state.loadingLatestNotifications = false;
          state.latestNotifications = action.payload;
        },
      )
      .addCase(fetchLatestNotifications.rejected, (state, action) => {
        state.loadingLatestNotifications = false;
      });
  },
});

export const fetchLatestNotifications = createAsyncThunk<
  PushNotification[],
  void,
  { state: RootState }
>('notifications/fetchLatestNotifications', async (_, { rejectWithValue, getState }) => {
  try {
    const response = await apiV1CrudsService.get('/push-notifications/latests');
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const { toggleNotificationIdOpened } = notificationsSlice.actions;
export default notificationsSlice.reducer;
