import LoadingComponent from '@/components/loadings/LoadingComponent';
import { useAppDispatch, useAppSelector } from '@/redux';
import {
  fetchLatestNotifications,
  toggleNotificationIdOpened,
} from '@/redux/slices/notificationsSlice';
import { PushNotification } from '@/types/models/push-notification';
import { apiV1CrudsService } from '@/utils/api-v1';
import { formatDate } from '@/utils/dates';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

export default function NotificationsModal() {
  const [notification, setNotification] = useState<PushNotification>();
  const { notificationIdOpened } = useAppSelector((state) => state.notifications);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleNotificationIdOpened(undefined));
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data } = await apiV1CrudsService.get(`/push-notifications/${notificationIdOpened}`);
        setNotification(data);
        await apiV1CrudsService.patch('/push-notifications/batch-mark-as-read', {
          notifications: [data._id],
        });
        dispatch(fetchLatestNotifications()); // Refresh notifications
      } catch {
        onClose();
      }
      setLoading(false);
    };

    if (!!notificationIdOpened) {
      getData();
    }
  }, [dispatch, notificationIdOpened, onClose]);

  const HAS_FILE = notification?.payload?.file?.key;

  return (
    <Dialog
      open={!!notificationIdOpened}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {loading ? 'Carregando...' : notification?.payload?.title}
      </DialogTitle>
      {loading ? (
        <DialogContent>
          <LoadingComponent />
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText sx={{ mt: 2 }} id="alert-dialog-description">
            <b>Mensagem:</b> {notification?.payload?.message}
          </DialogContentText>
          {HAS_FILE && (
            <DialogContentText sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={() => window.open(notification?.payload?.file?.url!)}
              >
                Visualizar Arquivo
              </Button>
            </DialogContentText>
          )}
          <Divider sx={{ my: 2 }} />
          <DialogContentText sx={{ mt: 2 }} id="alert-dialog-description">
            <b>Data:</b> {formatDate(notification?.createdAt)}
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }} id="alert-dialog-description">
            <b>Enviado por:</b> {notification?.sentBy?.name || 'Sistema'}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={onClose} disabled={loading}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
