import { RootState } from '@/redux';
import { ApiV1ConcurrentPricesResponse } from '@/types/apiv1/responses/concurrency/api-v1-concurreny-prices-response';
import { ConcurrentPension } from '@/types/enums/concurrency/concurrent-pension.enum';
import { Filters } from '@/types/general/filters';
import { apiV1ConcurrencyService } from '@/utils/api-v1';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface ConcurrencySliceState {
  concurrentPrices: ApiV1ConcurrentPricesResponse[];
  loadingConcurrentPrices: boolean;
  concurrentLowestPrices: ApiV1ConcurrentPricesResponse[];
  loadingConcurrentLowestPrices: boolean;
}

const initialState: ConcurrencySliceState = {
  concurrentPrices: [],
  loadingConcurrentPrices: false,
  concurrentLowestPrices: [],
  loadingConcurrentLowestPrices: false,
};

const concurrencySlice = createSlice({
  name: 'concurrency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConcurrentPrices.pending, (state) => {
        state.loadingConcurrentPrices = true;
      })
      .addCase(
        fetchConcurrentPrices.fulfilled,
        (state, action: PayloadAction<ApiV1ConcurrentPricesResponse[]>) => {
          state.loadingConcurrentPrices = false;
          state.concurrentPrices = action.payload;
        },
      )
      .addCase(fetchConcurrentPrices.rejected, (state, action) => {
        state.loadingConcurrentPrices = false;
      })
      .addCase(fetchConcurrentLowestPrices.pending, (state) => {
        state.loadingConcurrentLowestPrices = true;
      })
      .addCase(
        fetchConcurrentLowestPrices.fulfilled,
        (state, action: PayloadAction<ApiV1ConcurrentPricesResponse[]>) => {
          state.loadingConcurrentLowestPrices = false;
          state.concurrentLowestPrices = action.payload;
        },
      )
      .addCase(fetchConcurrentLowestPrices.rejected, (state, action) => {
        state.loadingConcurrentLowestPrices = false;
      });
  },
});

export const fetchConcurrentPrices = createAsyncThunk<
  ApiV1ConcurrentPricesResponse[],
  void,
  { state: RootState }
>('concurrency/fetchConcurrentPrices', async (_, { rejectWithValue, getState }) => {
  try {
    const params = getCommonFilters(getState().filters);
    const response = await apiV1ConcurrencyService.get(
      `/concurrent-prices/all?${stringify(params, { arrayFormat: 'bracket' })}`,
    );

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchConcurrentLowestPrices = createAsyncThunk<
  ApiV1ConcurrentPricesResponse[],
  void,
  { state: RootState }
>('concurrency/fetchConcurrentLowestPrices', async (_, { rejectWithValue, getState }) => {
  try {
    const params = getCommonFilters(getState().filters);
    const response = await apiV1ConcurrencyService.get(
      `/concurrent-prices/lowest?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// export const {} = concurrencySlice.actions;
export default concurrencySlice.reducer;

function getCommonFilters(filters: Filters) {
  const { concurrencyFilters } = filters;
  const {
    concurrentHotels,
    startDateBooking,
    endDateBooking,
    occupancy,
    pension,
    myRoomTypes,
    refundable,
  } = concurrencyFilters;

  const params: any = {
    concurrents: concurrentHotels.map((x) => x._id),
    startDate: formatDateApi(startDateBooking),
    endDate: formatDateApi(endDateBooking),
    roomTypes: myRoomTypes.map((x) => x._id),
    refundable,
  };

  if (ConcurrentPension[pension] !== undefined) {
    params.pension = pension;
  }

  if (occupancy !== 0) {
    params.occupancy = occupancy;
  }

  return params;
}
