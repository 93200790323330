import { LabelColor } from '@/components/Label';
import { DashIndex } from '@/types/enums/dashboards/dash-index.enum';
import { formatNumberByType, NumberTypes } from '../number';

export function getIsCurrencyByDashIndex(index: DashIndex) {
  switch (index) {
    case DashIndex.DAILY_AVERAGE:
    case DashIndex.REV_PAR:
    case DashIndex.REVENUE:
      return true;
    default:
      return false;
  }
}

export function getIsPercentageByDashIndex(index: DashIndex) {
  switch (index) {
    case DashIndex.OCCUPATION:
      return true;
    default:
      return false;
  }
}

export function getGenericChartTypeByIndex(
  index: DashIndex,
): 'currency' | 'percentage' | 'integer' {
  switch (index) {
    case DashIndex.DAILY_AVERAGE:
    case DashIndex.REV_PAR:
    case DashIndex.REVENUE:
    case DashIndex.REVENUE_FB:
    case DashIndex.REVENUE_DAILY:
    case DashIndex.FEE:
      return 'currency';
    case DashIndex.OCCUPATION:
      return 'percentage';
    case DashIndex.ROOM_NIGHT:
    case DashIndex.PAX:
    case DashIndex.PAX_CHD:
    case DashIndex.RESERVATIONS:
    case DashIndex.LOS:
    default:
      return 'integer';
  }
}

export function getValueHumanReadByIndex(value: number, index: DashIndex): string {
  return formatNumberByType(value, getNumberTypeByIndex(index), getDecimalPlacesByIndex(index));
}

export function getNumberTypeByIndex(index: DashIndex): NumberTypes {
  switch (index) {
    case DashIndex.DAILY_AVERAGE:
    case DashIndex.REVENUE:
    case DashIndex.REV_PAR:
      return 'money';
    case DashIndex.OCCUPATION:
      return 'percentage';
    case DashIndex.LOS:
      return 'decimal';
    case DashIndex.ROOM_NIGHT:
    case DashIndex.PAX:
    case DashIndex.PAX_CHD:
    case DashIndex.RESERVATIONS:
    default:
      return 'integer';
  }
}

export function getDecimalPlacesByIndex(index: DashIndex): number {
  switch (index) {
    case DashIndex.DAILY_AVERAGE:
    case DashIndex.REVENUE:
    case DashIndex.REV_PAR:
    case DashIndex.OCCUPATION:
    case DashIndex.LOS:
      return 2;
    case DashIndex.ROOM_NIGHT:
    case DashIndex.PAX:
    case DashIndex.PAX_CHD:
    case DashIndex.RESERVATIONS:
    default:
      return 0;
  }
}

export function getColorFromDelta(delta: number): LabelColor {
  if (delta === 0) {
    return 'warning';
  }
  if (delta > 0) {
    return 'success';
  }
  return 'error';
}

export function getColorFromGoalAchievement(achievement: number): LabelColor {
  if (achievement >= 100) {
    return 'success';
  }
  if (achievement >= 75) {
    return 'warning';
  }
  return 'error';
}
