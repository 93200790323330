import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import BarCell from '@/components/rms/fluctuation-table/BarCell';
import { RMS_EDIT_PRICE_ICON } from '@/config/icons';
import { defaultTableProps } from '@/config/table-config';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/redux';
import { openRmsFlutuationDetails, setFluctuationConfigOptions } from '@/redux/slices/rmsSlice';
import { Fluctuation } from '@/types/models/fluctuation';
import { FluctuationPrice } from '@/types/models/fluctuation-price';
import { apiV1RmsService } from '@/utils/api-v1';
import { formatCurrency } from '@/utils/number';
import { getOccupancyHumanReadString } from '@/utils/occupancy';
import { Button, Grid, Tooltip } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

interface Props {
  fluctuation: Fluctuation;
}

export default function FluctuationPricesTable({ fluctuation }: Props) {
  const dispatch = useAppDispatch();
  const { showSuccess } = useNotification();
  const [updating, setUpdating] = useState(false);

  const onSubmitCustomPrice = useCallback(
    async (price: FluctuationPrice) => {
      try {
        const customPrice = window.prompt(
          'Digite o valor personalizado:',
          price.customPrice?.toString(),
        );
        if (!customPrice) return;
        setUpdating(true);
        const params = {
          customPrice: +customPrice,
          usingCustomPrice: true,
          fluctuationPriceId: price._id,
        };
        await apiV1RmsService.put(`/fluctuations/custom-price/${fluctuation._id}`, params);
        dispatch(openRmsFlutuationDetails(fluctuation._id)); // Reload data
        showSuccess('Valor customizado atualizado com sucesso!', 5e3);
      } catch {
        //
      }
      setUpdating(false);
    },
    [dispatch, fluctuation._id, showSuccess],
  );

  const onRemoveCustomPrice = useCallback(
    async (price: FluctuationPrice) => {
      try {
        const answer = window.confirm(`Tem certeza que deseja remover o valor personalizado?`);
        if (!answer) return;
        setUpdating(true);
        const params = {
          customPrice: 0,
          usingCustomPrice: false,
          fluctuationPriceId: price._id,
        };
        await apiV1RmsService.put(`/fluctuations/custom-price/${fluctuation._id}`, params);
        showSuccess('Valor customizado removido com sucesso!', 5e3);
        dispatch(openRmsFlutuationDetails(fluctuation._id)); // Reload data
      } catch {
        //
      }
      setUpdating(false);
    },
    [dispatch, fluctuation._id, showSuccess],
  );

  const getRowActions = useMemo(
    () => (row: FluctuationPrice) => {
      const actions = [
        <Tooltip arrow title="Editar valor personalizado" placement="left" key="1">
          <GridActionsCellItem
            icon={<Iconify icon={RMS_EDIT_PRICE_ICON} sx={{ width: 16, height: 16 }} />}
            label="view"
            onClick={() => onSubmitCustomPrice(row)}
          />
        </Tooltip>,
      ];
      if (row.usingCustomPrice) {
        actions.push(
          <Tooltip
            arrow
            title="Remover valor personalizado e considerar sugestão"
            placement="left"
            key="2"
          >
            <GridActionsCellItem
              key="2"
              disabled={!row.usingCustomPrice}
              icon={<Iconify icon="mdi:money-off" sx={{ width: 16, height: 16, color: 'red' }} />}
              label="delete"
              onClick={() => onRemoveCustomPrice(row)}
            />
          </Tooltip>,
        );
      }
      return actions;
    },
    [onRemoveCustomPrice, onSubmitCustomPrice],
  );

  const columns = useMemo<GridColDef<FluctuationPrice>[]>(
    () => [
      {
        field: 'roomTypeCategory.roomType.name',
        headerAlign: 'center',
        headerName: 'Quarto',
        flex: 2,
        type: 'string',
        valueGetter: ({ row }) =>
          `${row.roomTypeCategory?.roomType?.name} - ${getOccupancyHumanReadString(
            row.roomTypeCategory?.occupancy,
          )} ${
            row.roomTypeCategory.isHotelDefault
              ? '(Padrão do hotel)'
              : row.roomTypeCategory.isRoomTypeDefault
              ? '(Padrão do quarto)'
              : ''
          }`,
      },
      {
        field: 'suggestedPrice',
        headerAlign: 'center',
        headerName: 'Sugestão',
        flex: 1,
        type: 'number',
        renderCell: ({ value, row }) =>
          row.usingRoomTypeMinPrice ? (
            <Tooltip arrow placement="top" title="Usando o preço mínimo do tipo de quarto">
              <span>
                <b>{formatCurrency(value, 2)}</b>
              </span>
            </Tooltip>
          ) : (
            <span>{formatCurrency(value, 2)}</span>
          ),
      },
      {
        field: 'appliedPrice',
        headerAlign: 'center',
        headerName: 'Último valor aplicado',
        flex: 1.5,
        type: 'number',
        renderCell: ({ value, row }) =>
          row.appliedPrice > 0 ? <span>{formatCurrency(value, 2)}</span> : <span>N/A</span>,
      },
      {
        field: 'customPrice',
        headerAlign: 'center',
        headerName: 'Valor personalizado',
        flex: 2,
        type: 'number',
        renderCell: ({ value, row }) =>
          row.usingCustomPrice ? <span>{formatCurrency(value, 2)}</span> : <span>N/A</span>,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        getActions: ({ row }: GridRowParams<FluctuationPrice>) => getRowActions(row),
      },
    ],
    [getRowActions],
  );

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} md={12}>
          <BarCell large bar={fluctuation.bar} />
        </Grid>
      </Grid>
      <Button
        sx={{ my: 2 }}
        variant="contained"
        size="small"
        onClick={() =>
          dispatch(
            setFluctuationConfigOptions({
              selectedDates: [new Date(fluctuation.date)],
              selectedRatePlans: [fluctuation.ratePlan],
              selectedRoomTypes: [fluctuation.roomType],
              selectedFluctuations: [fluctuation],
            }),
          )
        }
      >
        Alterar em lote
      </Button>
      <Scrollbar sx={{ width: 'auto' }}>
        <DataGrid<FluctuationPrice>
          {...defaultTableProps}
          paginationMode="client"
          sortingMode="client"
          pageSize={20}
          rows={fluctuation.prices}
          columns={columns}
          loading={updating}
          getRowClassName={({ row }) => (row.roomTypeCategory?.isRoomTypeDefault ? 'bold' : '')}
        />
      </Scrollbar>
    </>
  );
}
