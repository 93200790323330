import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

// https://iamhosseindhv.com/notistack/demos

export function useNotification() {
  const { enqueueSnackbar } = useSnackbar();
  const showSuccess = useCallback(
    (msg: string, autoHideDuration = 3000, onClick?: VoidFunction) => {
      enqueueSnackbar(msg, {
        variant: 'success',
        autoHideDuration,
        className: onClick ? 'pointer' : '',
        onClick: () => onClick && onClick(),
      });
    },
    [enqueueSnackbar],
  );

  const showError = useCallback(
    (msg: string, autoHideDuration = 3000, onClick?: VoidFunction) => {
      enqueueSnackbar(msg, {
        variant: 'error',
        autoHideDuration,
        className: onClick ? 'pointer' : '',
        onClick: () => onClick && onClick(),
      });
    },
    [enqueueSnackbar],
  );

  const showWarning = useCallback(
    (msg: string, autoHideDuration = 3000, onClick?: VoidFunction) => {
      enqueueSnackbar(msg, {
        variant: 'warning',
        autoHideDuration,
        className: onClick ? 'pointer' : '',
        onClick: () => onClick && onClick(),
      });
    },
    [enqueueSnackbar],
  );

  const showInfo = useCallback(
    (msg: string, autoHideDuration = 3000, onClick?: VoidFunction) => {
      enqueueSnackbar(msg, {
        variant: 'info',
        autoHideDuration,
        className: onClick ? 'pointer' : '',
        onClick: () => onClick && onClick(),
      });
    },
    [enqueueSnackbar],
  );

  return {
    showSuccess,
    showError,
    showWarning,
    showInfo,
  };
}
