import { RootState } from '@/redux';
import { ApiV1DashHomeResponse } from '@/types/apiv1/responses/dashboards/api-v1-home-responses';
import { DashHomeType } from '@/types/dashboards/home';
import { DashIndex } from '@/types/enums/dashboards/dash-index.enum';
import { apiV1DashboardService } from '@/utils/api-v1';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

const initialData: ApiV1DashHomeResponse = {
  cardTable: {
    tableData: [],
    widgetData: [],
  },
  [DashIndex.REVENUE]: {
    chartData: [],
    widgetData: [],
  },
  [DashIndex.ROOM_NIGHT]: {
    chartData: [],
    widgetData: [],
  },
  [DashIndex.DAILY_AVERAGE]: {
    chartData: [],
    widgetData: [],
  },
  [DashIndex.OCCUPATION]: {
    chartData: [],
    widgetData: [],
  },
  [DashIndex.REV_PAR]: {
    chartData: [],
    widgetData: [],
  },
  [DashIndex.PAX]: {
    widgetData: [],
    chartData: [],
  },
  [DashIndex.PAX_CHD]: {
    widgetData: [],
    chartData: [],
  },
  [DashIndex.RESERVATIONS]: {
    widgetData: [],
    chartData: [],
  },
  [DashIndex.LOS]: {
    widgetData: [],
    chartData: [],
  },
  [DashIndex.REVENUE_DAILY]: {
    widgetData: [],
    chartData: [],
  },
  [DashIndex.REVENUE_FB]: {
    widgetData: [],
    chartData: [],
  },
  [DashIndex.FEE]: {
    widgetData: [],
    chartData: [],
  },
};

interface DashHomeSliceState {
  data: ApiV1DashHomeResponse;
  loading: boolean;
}

const initialState: DashHomeSliceState = {
  data: initialData,
  loading: false,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHomeData.fulfilled, (state, action: PayloadAction<ApiV1DashHomeResponse>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchHomeData.rejected, (state) => {
        state.loading = false;
        state.data = initialData;
      });
  },
});

export const fetchHomeData = createAsyncThunk<
  ApiV1DashHomeResponse,
  { type: DashHomeType },
  { state: RootState }
>('home/fetchHomeData', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const { commonFilters } = getState().filters;
    const {
      startDate,
      endDate,
      granularity,
      perspective,
      segments = [],
      channels = [],
      showGoals,
      showLastMonth,
      showLastYear,
      reservationStatus,
    } = commonFilters;

    const params: any = {
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
      granularity,
      perspective,
      showGoals,
      showLastMonth,
      showLastYear,
      type,
      status: reservationStatus,
    };

    if (type === 'channels') {
      params.channels = channels.map((x) => x._id);
      if (params.channels.length === 0) {
        return initialData; // Evitar erro 429
      }
    } else if (type === 'segments') {
      params.segments = segments.map((x) => x._id);
      if (params.segments.length === 0) {
        return initialData; // Evitar erro 429
      }
    }

    const response = await apiV1DashboardService.get<ApiV1DashHomeResponse>(
      `/home?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export default homeSlice.reducer;
