// https://icon-sets.iconify.design

export const HOTEL_ICON = 'mdi:office-building-outline';
export const HOTEL_LEGACY_DATA_ICON = 'fluent-mdl2:decrease-indent-legacy';
export const ALL_HOTELS_ICON = 'maki:city';
export const CITY_ICON = 'healthicons:city-outline';
export const REGIONAL_ICON = 'mdi:office-building-marker-outline';
export const CHANNEL_ICON = 'fluent:channel-share-16-regular';
export const SEGMENT_ICON = 'ph:line-segments-light';
export const RESERVATION_ICON = 'bx:bed';
export const GUEST_ICON = 'bxs:user';
export const WALLET_ICON = 'la:wallet';
export const REVENUE_ICON = 'bi:cash-coin';
export const DAILY_AVERAGE_ICON = 'la:calendar-day';
export const REV_PAR_ICON = 'fluent:building-retail-money-20-regular';
export const OCCUPATION_ICON = 'carbon:hotel';
export const ROOM_NIGHT_ICON = 'material-symbols:night-shelter-outline-rounded';
export const BEDS_ICON = 'tabler:bed';

export const PICK_UP_ICON = 'ci:line-chart-down';
export const PICK_UP_FULL_ICON = 'ph:file-magnifying-glass';
export const PICK_UP_RITM_ICON = 'fluent:top-speed-20-regular';

export const RANKING_ICON = 'vaadin:pie-chart';

export const CONCURRENCY_ICON = 'tabler:brand-booking';

export const APP_LOADING_ICON = 'eos-icons:loading';
export const APP_REFRESH_ICON = 'solar:refresh-line-duotone';
export const APP_SYNC_ICON = 'mdi:sync';
export const APP_GLOBAL_FILTER_ICON = 'material-symbols:filter-alt';

export const EMISSION_SUMMARY_ICON = 'fluent:document-bullet-list-24-regular';
export const EMISSION_ALL_TABLE_ICON = 'fluent:document-bullet-list-24-regular';
export const EMISSION_LAST_YEAR_TABLE_ICON = 'fluent-mdl2:calendar-year';
export const EMISSION_CURRENT_PERIOD_TABLE_ICON = 'iwwa:year';
export const EMISSION_CHART_1_ICON = 'solar:chart-line-duotone';
export const EMISSION_CHART_2_ICON = 'gg:chart';
export const EMISSION_CHART_3_ICON = 'gg:chart-alt';

// RMS
export const RMS_ICON = 'ph:wave-sine-bold';
export const RMS_APPROVE_ICON = 'solar:like-line-duotone';
export const RMS_ABORT_ICON = 'solar:dislike-line-duotone';
export const RMS_RESTORE_ICON = 'ic:baseline-restore';
export const RMS_CONFIG_ICON = 'carbon:batch-job';
export const RMS_EDIT_PRICE_ICON = 'carbon:edit';

// GRI
export const GRI_ICON = 'icon-park-twotone:table-report';
