import Scrollbar from '@/components/Scrollbar';
import { defaultTableProps } from '@/config/table-config';
import { useAppSelector } from '@/redux';
import { FluctuationConfigOperation } from '@/types/enums/rms/fluctuation-config-operation.enum';
import { Fluctuation } from '@/types/models/fluctuation';
import { formatDate } from '@/utils/dates';
import { formatCurrency } from '@/utils/number';
import { getRmsFieldNameHumanReadable, getRmsVariation } from '@/utils/rms/fluctuation';
import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FluctuationConfigFormValuesProps } from '.';

export function FluctuationConfigTable() {
  const { selectedFluctuations } = useAppSelector((state) => state.rms.fluctuationConfigOptions);

  const { watch } = useFormContext<FluctuationConfigFormValuesProps>();
  const values = watch();

  const columns = useMemo<GridColDef<Fluctuation>[]>(
    () => [
      {
        field: 'date',
        headerName: 'Data',
        flex: 1,
        type: 'date',
        renderCell: ({ value }) => formatDate(value, 'dd/MM/yyyy (EEEEEE)'),
      },
      {
        field: 'roomType',
        headerName: 'Acomodação',
        flex: 1,
        type: 'string',
        valueGetter: ({ row }) => row.roomType?.name,
      },
      {
        field: 'suggestedPrice',
        headerName: getRmsFieldNameHumanReadable('suggestedPrice'),
        flex: 1,
        type: 'number',
        cellClassName: values.additionalFieldRef === 'suggestedPrice' ? 'bold positive' : '',
        renderCell: ({ value }) => formatCurrency(value, 2),
      },
      {
        field: 'appliedPrice',
        headerName: getRmsFieldNameHumanReadable('appliedPrice'),
        flex: 1,
        type: 'number',
        cellClassName: values.additionalFieldRef === 'appliedPrice' ? 'bold positive' : '',
        renderCell: ({ value, row }) =>
          row.appliedPrice > 0 ? <span>{formatCurrency(value, 2)}</span> : <span>N/A</span>,
      },
      {
        field: 'customPrice',
        headerName: getRmsFieldNameHumanReadable('customPrice'),
        flex: 1,
        type: 'number',
        cellClassName: values.additionalFieldRef === 'customPrice' ? 'bold positive' : '',
        renderCell: ({ value, row }) =>
          row.customPrice > 0 ? <span>{formatCurrency(value, 2)}</span> : <span>N/A</span>,
      },
      {
        field: 'updatedPrice',
        headerName: 'Novo valor',
        flex: 1,
        type: 'number',
        cellClassName: 'bg-warning bold',
        valueGetter: ({ row }) => {
          if (values.operation === FluctuationConfigOperation.DISABLE_ALL_CUSTOM_VALUES) {
            return row.suggestedPrice;
          }

          if (values.additionalFieldRef === 'absolute') {
            return +values.additionalPrice;
          }

          const BASE_PRICE =
            values.additionalFieldRef === 'appliedPrice'
              ? row.appliedPrice
              : row[values.additionalFieldRef];

          return getRmsVariation(BASE_PRICE, +values.additionalPrice, values.additionalPriceType);
        },
        renderCell: ({ value }) => formatCurrency(value, 2),
      },
    ],
    [values],
  );

  return (
    <>
      <Typography variant="body2">
        <b>Detalhamento das mudanças a serem efetuadas:</b>
      </Typography>
      <Scrollbar sx={{ width: 'auto' }}>
        <DataGrid
          {...defaultTableProps}
          paginationMode="client"
          sortingMode="client"
          pageSize={15}
          rows={selectedFluctuations!}
          columns={columns}
        />
      </Scrollbar>
    </>
  );
}
