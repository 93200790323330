import FluctuationConfigModal from './FluctuationConfigModal';
import FluctuationDetailsModal from './FluctuationDetailsModal';
import FluctuationLogDetailsModal from './FluctuationLogDetailsModal';
import HotelLegacyDataModal from './HotelLegacyDataModal';
import NotificationsModal from './NotificationsModal';
import PickUpJobModal from './PickUpJobModal';
import RevenueJobModal from './RevenueJobModal';
import SavedSearchModal from './SavedSearchModal';

export default function GlobalModals() {
  return (
    <>
      <FluctuationDetailsModal />
      <FluctuationLogDetailsModal />
      <FluctuationConfigModal />
      <PickUpJobModal />
      <HotelLegacyDataModal />
      <RevenueJobModal />
      <SavedSearchModal />
      <NotificationsModal />
    </>
  );
}
