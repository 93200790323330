import IconButtonAnimate from '@/components/animate/IconButtonAnimate';
import Iconify from '@/components/Iconify';
import MenuPopover from '@/components/MenuPopover';
import Scrollbar from '@/components/Scrollbar';
import { formatDate } from '@/utils/dates';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useSession } from '@/hooks/auth/useSession';
import { useNotification } from '@/hooks/useNotification';
import { PushNotification } from '@/types/models/push-notification';

import { useAppDispatch, useAppSelector } from '@/redux';
import {
  fetchLatestNotifications,
  toggleNotificationIdOpened,
} from '@/redux/slices/notificationsSlice';
import { apiV1CrudsService } from '@/utils/api-v1';
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';

export default function NotificationsPopover() {
  const { latestNotifications } = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();
  const { user } = useSession();
  const { showInfo } = useNotification();
  const navigate = useNavigate();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const openNotification = async (notification: PushNotification) => {
    try {
      handleClose();
      dispatch(toggleNotificationIdOpened(notification._id));
    } catch {
      //
    }
  };

  const setAllRead = async () => {
    try {
      const notifications = latestNotifications
        .filter((item) => !item?.readAt)
        .map((item) => item._id);
      await apiV1CrudsService.patch('/push-notifications/batch-mark-as-read', {
        notifications,
      });
      dispatch(fetchLatestNotifications());
    } catch {
      //
    }
  };

  useEffect(() => {
    const { REACT_APP_PUSHER_KEY = null, REACT_APP_PUSHER_CLUSTER = 'mt1' } = process.env;

    if (!!REACT_APP_PUSHER_KEY) {
      const channel = user._id;
      const pusher = new Pusher(REACT_APP_PUSHER_KEY, { cluster: REACT_APP_PUSHER_CLUSTER });
      pusher.subscribe(channel);
      pusher.bind('PUSH_NOTIFICATION', async (data: PushNotification['payload']) => {
        dispatch(fetchLatestNotifications());
        showInfo(data.title, 4e3, () => dispatch(toggleNotificationIdOpened(data.correlationId)));
      });
      pusher.bind('TOAST_NOTIFICATION', async (data: { message: string }) => {
        showInfo(data.message, 4e3);
      });

      return () => {
        pusher.disconnect();
      };
    }
  }, [dispatch, showInfo, user._id]);

  const total = latestNotifications.length;
  const totalUnRead = useMemo(
    () => latestNotifications.filter((item) => !item?.readAt).length,
    [latestNotifications],
  );

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificações</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Você tem {totalUnRead}{' '}
              {totalUnRead <= 0 || totalUnRead > 1 ? 'notificações' : 'notificação'} não{' '}
              {totalUnRead <= 0 || totalUnRead > 1 ? 'lidas' : 'lida'}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip arrow title="Marcar tudo como lido">
              <IconButton color="primary" onClick={() => setAllRead()}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        {total > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Scrollbar sx={{ height: { xs: 340, sm: 'auto', maxHeight: 500 } }}>
              <List
                disablePadding
                subheader={
                  <ListSubheader
                    disableSticky
                    sx={{ my: 2, typography: 'overline', textAlign: 'center' }}
                  >
                    Últimas {total} notificações
                  </ListSubheader>
                }
              >
                {latestNotifications.map((x) => (
                  <NotificationItem key={x._id} data={x} onClick={() => openNotification(x)} />
                ))}
              </List>
            </Scrollbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => {
              handleClose();
              navigate('/push-notifications');
            }}
            sx={{ textTransform: 'none' }}
          >
            Abrir página de notificações
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

function NotificationItem({ data, onClick }: { data: PushNotification; onClick: VoidFunction }) {
  const { payload, readAt, createdAt } = data;

  const PRIMARY_OPACITY = 'rgba(1, 171, 85, 0.05)';

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        m: 1,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        ...(!readAt && {
          bgcolor: PRIMARY_OPACITY,
        }),
        ':hover': {
          bgcolor: readAt ? 'action.selected' : PRIMARY_OPACITY,
        },
      }}
      onClick={onClick}
    >
      <ListItemText
        primary={payload.title}
        primaryTypographyProps={{ fontSize: 'small' }}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify
              icon={!!data.readAt ? 'eva:checkmark-circle-2-outline' : 'eva:clock-outline'}
              sx={{
                mr: 0.5,
                width: 16,
                height: 16,
                color: !!data.readAt ? 'green' : 'red',
              }}
            />
            {formatDate(createdAt, 'dd/MM/yyyy - HH:mm:ss')}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
