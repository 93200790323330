export function formatCurrency(value: any, decimalPlaces = 2): string {
  try {
    return Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    }).format(value);
  } catch (error) {
    console.log(`formatCurrency LOG:  error`, error);
    return value;
  }
}

export function formatNumberToCsv(value: any, decimalPlaces = 2): string {
  if (typeof value !== 'number') {
    return value;
  }
  let valueString = value.toFixed(decimalPlaces);
  valueString = valueString.replace('.', ',');
  return valueString;
}

export function formatPercentage(value: any, decimalPlaces = 2): string {
  try {
    return (
      Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
      }).format(value) + '%'
    );
  } catch (error) {
    console.log(`formatPercentage LOG:  error`, error);
    return value;
  }
}

export type NumberTypes = 'money' | 'integer' | 'decimal' | 'percentage';
export function formatNumberByType(
  value: number,
  valueType: NumberTypes,
  decimalPlaces = 2,
): string {
  try {
    switch (valueType) {
      case 'money':
        return formatCurrency(value, decimalPlaces);
      case 'integer':
        return value.toLocaleString();
      case 'decimal':
        return value.toFixed(decimalPlaces).toLocaleString();
      case 'percentage':
        return formatPercentage(value, decimalPlaces);
      default:
        return `${value}`;
    }
  } catch (error) {
    console.log(`formatNumberByType LOG:  error`, error);
    return `${value}`;
  }
}

export function sumAndRound(a: number, b: number, decimalPlaces = 2): number {
  return parseFloat((a + b).toFixed(decimalPlaces));
}

export function subtractAndRound(a: number, b: number, decimalPlaces = 2): number {
  return parseFloat((a - b).toFixed(decimalPlaces));
}

export function isNumber(value: any) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function getBenchmarkVariation(value1: number, value2: number): number {
  const diff = value1 - value2;
  let variationPercentage = 0;
  if (value2 > 0) {
    variationPercentage = +((diff / value2) * 100).toFixed(2);
  }
  return variationPercentage;
}

/**
 * @description retorna a meta atingida em porcentagem
 */
export function getGoalPercentage(value: number, valueGoal: number): number {
  if (valueGoal === 0) {
    return 0;
  }
  return +((value / valueGoal) * 100).toFixed(2);
}

export function randomInteger(min: number, max: number): number {
  if (min > max) {
    throw new Error('Min value should not be greater than max value');
  }
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
