import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashGranularity {
  DAY = 'DAY',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  WEEK = 'WEEK', // As semanas começam aos domingos, e a semana 1 começa com o primeiro domingo do ano. Os dias que precedem o primeiro domingo do ano estão na semana 0.
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export function getDashGranularitHumanRead(value: DashGranularity) {
  switch (value) {
    case DashGranularity.DAY:
      return 'Dia';
    case DashGranularity.MONTH:
      return 'Mês';
    case DashGranularity.YEAR:
      return 'Ano';
    case DashGranularity.DAY_OF_WEEK:
      return 'Dia da semana';
    case DashGranularity.WEEK:
      return 'Semana';
    default:
      return value;
  }
}

/**
 * @deprecated aplicar todas as granularidades nos relatórios que ainda não possuem (requer adaptação na api)
 */
export const oldDashGranularities: GenericIdName[] = [
  DashGranularity.DAY,
  DashGranularity.MONTH,
].map((x) => ({
  _id: x,
  name: getDashGranularitHumanRead(x),
}));

export const allDashGranularities: GenericIdName[] = Object.values(DashGranularity).map((x) => ({
  _id: x,
  name: getDashGranularitHumanRead(x),
}));
