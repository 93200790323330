import Scrollbar from '@/components/Scrollbar';
import RoomTypeCategoriesColorLegend from '@/components/rms/room-types/RoomTypeCategoriesColorLegend';
import {
  defaultColumnWidth,
  defaultNumberColumnWidth,
  defaultTableProps,
} from '@/config/table-config';
import { useDebounce } from '@/hooks/useDebounce';
import { TariffLinePricesSimulation } from '@/types/models/tariff-line';
import { apiV1RmsService } from '@/utils/api-v1';
import { formatCurrency } from '@/utils/number';
import { getOccupancyHumanReadString } from '@/utils/occupancy';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { stringify } from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  defaultBasePrice?: number;
  roomTypes?: string[];
}

export default function TariffLinePricesSimulationCard({
  defaultBasePrice = 100,
  roomTypes = [],
}: Props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TariffLinePricesSimulation[]>([]);
  const [basePrice, setBasePrice] = useState(defaultBasePrice);
  const debouncedBasePrice = useDebounce(basePrice, 800);

  const url = useMemo(() => {
    const params = {
      basePrice: debouncedBasePrice,
      roomTypes,
    };
    return `/fluctuations/tariff-line-prices-simulations?${stringify(params, {
      arrayFormat: 'bracket',
    })}`;
  }, [debouncedBasePrice, roomTypes]);

  const getSimulationData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await apiV1RmsService.get(url);
      setData(data);
    } catch (err) {
      console.error(err);
      //
    }
    setLoading(false);
  }, [url]);

  useEffect(() => {
    getSimulationData();
  }, [debouncedBasePrice, getSimulationData]);
  const sample = data[0];

  const roomTypeColumns = useMemo<GridColDef<TariffLinePricesSimulation>[]>(() => {
    if (!sample) {
      return [];
    }
    const { roomTypesPrices = [] } = sample;

    return roomTypesPrices.flatMap(
      ({ name, categories }, roomTypesPriceIndex) =>
        categories.map(({ occupancy, isHotelDefault, isRoomTypeDefault }, index) => ({
          field: `${name}-${occupancy}`,
          headerName: `${name} - ${getOccupancyHumanReadString(occupancy)}`,
          flex: 1,
          valueFormatter: ({ value }) => formatCurrency(value),
          type: 'number',
          valueGetter: ({ row }) =>
            row.roomTypesPrices[roomTypesPriceIndex]?.categories[index]?.price || 0,
          width: defaultNumberColumnWidth,
          headerClassName: () => {
            if (isHotelDefault) {
              return 'room-type-category-hotel-default';
            }

            if (isRoomTypeDefault) {
              return 'room-type-category-default';
            }

            return '';
          },
        })),
      [],
    );
  }, [sample]);

  const columns = useMemo<GridColDef<TariffLinePricesSimulation>[]>(
    () => [
      {
        field: 'name',
        headerName: 'LINHA TARIFÁRIA',
        width: defaultColumnWidth + 100,
      },
      ...roomTypeColumns,
    ],
    [roomTypeColumns],
  );

  return (
    <Card sx={{ mt: 4 }}>
      <CardHeader
        title="Calculadora de flutuação por linhas tarifárias"
        subheader="Não considera a taxa de ocupação/BAR"
      />
      <CardContent>
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              disabled={loading}
              label="Valor de referência"
              value={basePrice}
              autoFocus
              onChange={(event) => setBasePrice(+event.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ borderStyle: 'dashed', my: 2 }} />
        <Scrollbar sx={{ width: 'auto' }}>
          <DataGrid<TariffLinePricesSimulation>
            {...defaultTableProps}
            paginationMode="client"
            sortingMode="client"
            pageSize={31}
            rows={data}
            loading={loading}
            components={{ ...defaultTableProps.components, Pagination: null }} // Pagination disabled
            columns={columns}
          />
        </Scrollbar>
      </CardContent>
      <CardActions>
        <RoomTypeCategoriesColorLegend />
      </CardActions>
    </Card>
  );
}
