import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import { varFade } from '@/components/animate/variants/fade';
import { NAVBAR } from '@/config/layout';
import { defaultTableProps } from '@/config/table-config';
import { useSession } from '@/hooks/auth/useSession';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/redux';
import { updateUserSession } from '@/redux/slices/authSlice';
import {
  applySavedSearchFilters,
  toggleSavedSearchDrawerOpened,
  toggleSavedSearchModalOpened,
} from '@/redux/slices/filtersSlice';
import { ApiV1UpdateUserSessionRequest } from '@/types/apiv1/requests/api-v1-update-user-session-request';
import { getAppPageHumanRead, getPathFromAppPage } from '@/types/enums/app-page.enum';
import { getUserSessionModeHumaRead } from '@/types/enums/user-session-mode.enum';
import { SavedSearch } from '@/types/models/saved-search';
import { apiV1CrudsService } from '@/utils/api-v1';
import { cssStyles } from '@/utils/css-styles';
import { hasPermissionToEditSavedSearch } from '@/utils/saved-search';
import { Backdrop, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { AnimatePresence, m } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16,
  )}`,
  [theme.breakpoints.up('sm')]: {
    width: 500, // Width for small screens and up
  },
  [theme.breakpoints.up('md')]: {
    width: 900, // Width for medium screens and up
  },
  [theme.breakpoints.up('lg')]: {
    width: 1000, // Width for large screens and up
  },
  [theme.breakpoints.up('xl')]: {
    width: 1300, // Width for extra large screens and up
  },
}));

export default function SavedSearchesDrawer() {
  const { showSuccess } = useNotification();
  const session = useSession();
  const { drawerOpened } = useAppSelector((state) => state.filters.savedSearch);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<SavedSearch[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const varSidebar = varFade({
    distance: NAVBAR.BASE_WIDTH,
    durationIn: 0.32,
    durationOut: 0.32,
  }).inRight;

  useEffect(() => {
    if (drawerOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [drawerOpened]);

  const handleClose = useCallback(() => {
    dispatch(toggleSavedSearchDrawerOpened());
  }, [dispatch]);

  const handleApply = useCallback(
    async (savedSearch: SavedSearch) => {
      dispatch(applySavedSearchFilters(savedSearch));
      dispatch(toggleSavedSearchDrawerOpened());
      navigate(getPathFromAppPage(savedSearch.page));

      const useSessionParams: ApiV1UpdateUserSessionRequest = {
        mode: savedSearch?.userSession?.mode,
        hotels: savedSearch?.userSession?.hotels.map((x) => x._id),
        regionals: savedSearch?.userSession?.regionals.map((x) => x._id),
        cities: savedSearch?.userSession?.cities.map((x) => x._id),
        wallets: savedSearch?.userSession?.wallets.map((x) => x._id),
      };
      await dispatch(updateUserSession(useSessionParams));
      showSuccess('Filtros aplicados!');
    },
    [dispatch, navigate, showSuccess],
  );

  const getData = useCallback(async (showLoading = true) => {
    try {
      setLoading(showLoading);

      const { data } = await apiV1CrudsService.get('/saved-searches');
      setData(data);
    } catch (err) {
      console.error(err);
      //
    }
    setLoading(false);
  }, []);

  const handleDelete = useCallback(
    async (row: SavedSearch) => {
      const prompt = window.confirm('Deseja realmente excluir este filtro?');
      if (!prompt) return;
      try {
        await apiV1CrudsService.delete(`/saved-searches/${row._id}`);
        showSuccess('Filtro excluído com sucesso!');
        getData(false);
      } catch {
        //
      }
    },
    [getData, showSuccess],
  );

  useEffect(() => {
    if (drawerOpened) {
      getData(true);
    }
  }, [drawerOpened, getData]);

  const columns = useMemo<GridColDef<SavedSearch>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Nome',
        flex: 2,
        renderCell: ({ row }) =>
          !!row.description ? (
            <Tooltip title={row.description}>
              <Typography
                onClick={() => handleApply(row)}
                variant="subtitle2"
                sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 'bold' }}
              >
                {row.name}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              onClick={() => handleApply(row)}
              variant="subtitle2"
              sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 'bold' }}
            >
              {row.name}
            </Typography>
          ),
      },
      {
        field: 'page',
        headerName: 'Página',
        flex: 0.8,
        valueGetter: ({ row }) => getAppPageHumanRead(row.page),
      },
      {
        field: 'owner',
        headerName: 'Proprietário',
        flex: 0.8,
        valueGetter: ({ row }) => row.owner?.name,
      },
      {
        field: 'userSession',
        headerName: 'Contexto',
        flex: 1,
        valueGetter: ({ row }) => {
          const mode = getUserSessionModeHumaRead(row.userSession?.mode);
          const hotelText =
            row.userSession?.hotels.length > 1
              ? `${row.userSession?.hotels.length} hotéis`
              : row.userSession?.lastHotelSelected?.slug;

          return `${mode}: ${hotelText}`;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        getActions: ({ row }: GridRowParams<SavedSearch>) => {
          const hasPermissionToEdit = hasPermissionToEditSavedSearch(session, row);
          const columns = [
            <Tooltip key="1" title="Aplicar filtros">
              <GridActionsCellItem
                icon={
                  <Iconify icon="fluent-mdl2:filter-descending" sx={{ width: 16, height: 16 }} />
                }
                label="view"
                onClick={() => handleApply(row)}
              />
            </Tooltip>,
          ];

          if (hasPermissionToEdit) {
            columns.push(
              <Tooltip key="2" title="Editar">
                <GridActionsCellItem
                  icon={<Iconify icon="ep:edit" sx={{ width: 16, height: 16 }} />}
                  label="edit"
                  onClick={() => {
                    handleClose();
                    dispatch(toggleSavedSearchModalOpened({ modalOpened: true, savedSearch: row }));
                  }}
                />
              </Tooltip>,
              <Tooltip key="3" title="Excluir">
                <GridActionsCellItem
                  icon={<Iconify icon="ep:delete-filled" sx={{ width: 16, height: 16 }} />}
                  label="delete"
                  onClick={() => handleDelete(row)}
                />
              </Tooltip>,
            );
          }

          return columns;
        },
      },
    ],
    [dispatch, handleApply, handleClose, handleDelete, session],
  );

  return (
    <>
      <Backdrop
        open={drawerOpened}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      <AnimatePresence>
        {drawerOpened && (
          <>
            <RootStyle {...varSidebar}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 2, pr: 1, pl: 2.5 }}
              >
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  Filtros Salvos
                </Typography>

                <IconButton onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <Scrollbar sx={{ flexGrow: 1 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Stack spacing={1.5} sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle2">
                      Meus filtros salvos ou compartilhados comigo
                    </Typography>
                  </Stack>
                  <Stack spacing={1.5}>
                    <DataGrid
                      {...defaultTableProps}
                      components={{
                        ...defaultTableProps.components,
                        Pagination: null, // disable pagination
                        Toolbar: null, // disable toolbar
                        Footer: () => <></>, // Hide footer/disable footer
                      }}
                      paginationMode="client"
                      sortingMode="client"
                      pageSize={15}
                      rows={data}
                      loading={loading}
                      columns={columns}
                      rowHeight={60}
                      disableColumnMenu
                      disableColumnFilter
                      disableColumnSelector
                    />
                  </Stack>
                </Stack>
              </Scrollbar>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
