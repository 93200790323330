import { GenericIdName } from '@/types/models/generic-id-name';

export function getOccupancyHumanReadString(occupancy: number) {
  switch (occupancy) {
    case 1:
      return 'SGL';
    case 2:
      return 'DBL';
    case 3:
      return 'TPL';
    case 4:
      return 'QDP';
    case 5:
      return 'QTP';
    case 6:
      return 'SEX';
    case 7:
      return 'SET';
    case 8:
      return 'OCT';
    case 9:
      return 'NON';
    case 10:
      return 'DEC';
    default:
      return occupancy?.toString();
  }
}

export const occupancyOptions: GenericIdName[] = [1, 2, 3, 4, 5, 6].map((x) => ({
  _id: x.toString(),
  name: `${x} (${getOccupancyHumanReadString(x)})`,
}));
