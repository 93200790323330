import Label from '@/components/Label';
import { defaultTableProps } from '@/config/table-config';
import { useAppSelector } from '@/redux';
import {
  getRmsActionLogOperationColor,
  getRmsActionLogOperationHumanReadable,
} from '@/types/enums/rms/rms-action-log-operation.enum';
import { RmsActionLog } from '@/types/models/rms-action-log';
import { apiV1RmsService } from '@/utils/api-v1';
import { formatDate } from '@/utils/dates';
import { getRmsFieldNameHumanReadable } from '@/utils/rms/fluctuation';
import { Card, CardContent, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

export default function FluctuationHistoricContainer() {
  const [data, setData] = useState<RmsActionLog[]>([]);
  const [loading, setLoading] = useState(true);
  const { fluctuationDetailsModalOpened } = useAppSelector((state) => state.rms!);
  const { fluctuation } = fluctuationDetailsModalOpened!;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await apiV1RmsService.get(`/action-logs/${fluctuation._id}`);
      setData(data);
    } catch {
      //
    }
    setLoading(false);
  }, [fluctuation._id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo<GridColDef<RmsActionLog>[]>(
    () => [
      {
        field: 'date',
        headerName: 'Data e Hora',
        flex: 0.8,
        renderCell: ({ row }) => <>{formatDate(row.date)}</>,
      },
      {
        field: 'name',
        headerName: 'Usuário',
        flex: 2,
        valueGetter: ({ row }) => row.user.name,
      },
      {
        field: 'operation',
        headerName: 'Operação',
        flex: 0.8,
        renderCell: ({ value }) => (
          <Label color={getRmsActionLogOperationColor(value)}>
            {getRmsActionLogOperationHumanReadable(value)}
          </Label>
        ),
      },
      {
        field: 'fieldName',
        headerName: 'Campo',
        flex: 2,
        valueGetter: ({ value }) => getRmsFieldNameHumanReadable(value),
      },
      // {
      //   field: 'modelName',
      //   headerName: 'Model',
      //   flex: 1,
      //   valueGetter: ({ row }) => getRmsActionLogModelNameHumanReadable(row.modelName),
      // },
      {
        field: 'oldValue',
        headerName: 'Valor antigo',
        flex: 0.5,
        renderCell: ({ value, row }) => {
          if (row.fieldName === 'color') {
            return (
              <Typography textTransform="capitalize" sx={{ color: value }}>
                {value}
              </Typography>
            );
          }
          return value;
        },
      },
      {
        field: 'newValue',
        headerName: 'Novo valor',
        flex: 0.5,
        renderCell: ({ value, row }) => {
          if (row.fieldName === 'color') {
            return (
              <Typography textTransform="capitalize" sx={{ color: value }}>
                {value}
              </Typography>
            );
          }
          return value;
        },
      },
    ],
    [],
  );

  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <DataGrid<RmsActionLog>
            {...defaultTableProps}
            paginationMode="client"
            sortingMode="client"
            pageSize={20}
            rows={data}
            columns={columns}
            loading={loading}
          />
        </Paper>
      </CardContent>
    </Card>
  );
}
