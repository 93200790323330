import Scrollbar from '@/components/Scrollbar';
import { defaultTableProps } from '@/config/table-config';
import { useAppSelector } from '@/redux';
import { SavedSearchPermission } from '@/types/models/saved-search';
import { apiV1CrudsService } from '@/utils/api-v1';
import { Divider, FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

export default function PermissionsTable() {
  const [submitingUsers, setSubmitingUsers] = useState(false);
  const [submitingWallets, setSubmitingWallets] = useState(false);
  const { savedSearch } = useAppSelector((state) => state.filters.savedSearch);
  const [users, setUsers] = useState<SavedSearchPermission[]>(savedSearch?.users ?? []);
  const [wallets, setWallets] = useState<SavedSearchPermission[]>(savedSearch?.wallets ?? []);

  const onSubmitUser = useCallback(
    async (user: SavedSearchPermission, permission: SavedSearchPermission['permission']) => {
      try {
        setSubmitingUsers(true);
        const params = { users: [user._id], permission };
        await apiV1CrudsService.put(`/saved-searches/${savedSearch?._id}/permissions`, params);
      } catch {}
      setSubmitingUsers(false);
    },
    [savedSearch?._id],
  );

  const onSubmitWallet = useCallback(
    async (wallet: SavedSearchPermission, permission: SavedSearchPermission['permission']) => {
      try {
        setSubmitingWallets(true);
        const params = { wallets: [wallet._id], permission };
        await apiV1CrudsService.put(`/saved-searches/${savedSearch?._id}/permissions`, params);
      } catch {}
      setSubmitingWallets(false);
    },
    [savedSearch?._id],
  );

  const userColumns = useMemo<GridColDef<SavedSearchPermission>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Usuário',
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
      },
      {
        field: 'permissions',
        headerName: 'Ações',
        flex: 1,
        renderCell: ({ row }) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={submitingUsers}
                  checked={row.permission === 'write'}
                  onChange={({ target }) => {
                    const permission = target.checked ? 'write' : 'read';
                    setUsers((prev) =>
                      prev.map((u) => (u._id === row._id ? { ...u, permission } : u)),
                    );
                    onSubmitUser(row, permission);
                  }}
                />
              }
              label={row.permission === 'write' ? 'Escrita' : 'Leitura'}
            />
          </FormGroup>
        ),
      },
    ],
    [onSubmitUser, submitingUsers],
  );

  const walletsColumns = useMemo<GridColDef<SavedSearchPermission>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Carteira',
        flex: 1,
      },
      {
        field: 'permissions',
        headerName: 'Ações',
        flex: 1,
        renderCell: ({ row }) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={submitingUsers}
                  checked={row.permission === 'write'}
                  onChange={({ target }) => {
                    const permission = target.checked ? 'write' : 'read';
                    setWallets((prev) =>
                      prev.map((u) => (u._id === row._id ? { ...u, permission } : u)),
                    );
                    onSubmitWallet(row, permission);
                  }}
                />
              }
              label={row.permission === 'write' ? 'Escrita' : 'Leitura'}
            />
          </FormGroup>
        ),
      },
    ],
    [onSubmitWallet, submitingUsers],
  );

  return (
    <Scrollbar sx={{ flexGrow: 1 }}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack spacing={1.5} sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle2">Ajuste de permissões Usuários</Typography>
        </Stack>
        <Stack spacing={1.5}>
          <DataGrid
            {...defaultTableProps}
            components={{
              ...defaultTableProps.components,
              Pagination: null, // disable pagination
              Toolbar: null, // disable toolbar
              Footer: () => <></>, // Hide footer/disable footer
            }}
            paginationMode="client"
            sortingMode="client"
            pageSize={15}
            rows={users}
            loading={submitingUsers}
            columns={userColumns}
            rowHeight={60}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={1.5} sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle2">Ajuste de permissões Carteiras</Typography>
        </Stack>
        <Stack spacing={1.5}>
          <DataGrid
            {...defaultTableProps}
            components={{
              ...defaultTableProps.components,
              Pagination: null, // disable pagination
              Toolbar: null, // disable toolbar
              Footer: () => <></>, // Hide footer/disable footer
            }}
            paginationMode="client"
            sortingMode="client"
            pageSize={15}
            rows={wallets}
            loading={submitingWallets}
            columns={walletsColumns}
            rowHeight={60}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Stack>
      </Stack>
    </Scrollbar>
  );
}
