import { useAppSelector } from '@/redux';
import { AppPage } from '@/types/enums/app-page.enum';

export default function useAppliedFilters(page: AppPage) {
  const {
    commonFilters,
    rmsFilters,
    pickUpOldFilters,
    pickUpFilters,
    concurrencyFilters,
    emissionFilters,
    griFilters,
  } = useAppSelector((state) => state.filters);

  const appliedFilters: any = { commonFilters };

  switch (page) {
    case AppPage.HOME:
    case AppPage.RANKING:
    case AppPage.HOME_V2:
    case AppPage.BENCHMARK:
      return appliedFilters;
    case AppPage.GRI:
      appliedFilters.griFilters = griFilters;
      return appliedFilters;
    case AppPage.PICKUP_HOTELS:
    case AppPage.PICKUP_SEGMENTS:
    case AppPage.PICKUP_CHANNELS:
      appliedFilters.pickUpOldFilters = pickUpOldFilters;
      return appliedFilters;
    case AppPage.PICKUP_V2:
    case AppPage.PICKUP_V2_RITM:
      appliedFilters.pickUpFilters = pickUpFilters;
      return appliedFilters;
    case AppPage.CONCURRENT_PRICES:
      appliedFilters.concurrencyFilters = concurrencyFilters;
      return appliedFilters;
    case AppPage.RMS_FLUCTUATIONS:
    case AppPage.RMS_FLUCTUATIONS_CALENDAR:
      appliedFilters.rmsFilters = rmsFilters;
      return appliedFilters;
    case AppPage.EMISSIONS_HOTELS:
    case AppPage.EMISSIONS_SEGMENTS:
    case AppPage.EMISSIONS_CHANNELS:
      appliedFilters.emissionFilters = emissionFilters;
      return appliedFilters;
    default:
      return appliedFilters;
  }
}
