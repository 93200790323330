import { GenericIdName } from '@/types/models/generic-id-name';

export enum ConcurrentPension {
  ROOM_ONLY = 'ROOM_ONLY',
  BED_AND_BREAKFAST = 'BED_AND_BREAKFAST',
  HALF_BOARD = 'HALF_BOARD', // Mei
  FULL_BOARD = 'FULL_BOARD',
  ALL_INCLUSIVE = 'ALL_INCLUSIVE',
}

export function getConcurrentPensionHumanRead(pension: ConcurrentPension): string {
  switch (pension) {
    case ConcurrentPension.ROOM_ONLY:
      return 'Somente quarto';
    case ConcurrentPension.BED_AND_BREAKFAST:
      return 'Cama e café';
    case ConcurrentPension.HALF_BOARD:
      return 'Meia pensão'; // Café e mais uma refeição (almoço ou janta)
    case ConcurrentPension.FULL_BOARD:
      return 'Pensão completa'; // Não é all inclusive
    case ConcurrentPension.ALL_INCLUSIVE:
      return 'All inclusive';
    default:
      return pension;
  }
}

export const concurrentPensionOptions: GenericIdName[] = Object.keys(ConcurrentPension).map(
  (key) => ({
    _id: key,
    name: getConcurrentPensionHumanRead(key as ConcurrentPension),
  }),
);
