import Label from '@/components/Label';
import { defaultTableProps } from '@/config/table-config';
import { useAppSelector } from '@/redux';
import { FluctuationLog } from '@/types/models/fluctuation-log';
import { apiV1RmsService } from '@/utils/api-v1';
import { formatDate } from '@/utils/dates';
import { formatCurrency, formatPercentage } from '@/utils/number';
import { Card, CardContent, Paper, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

export default function FluctuationIntegrationContainer() {
  const [data, setData] = useState<FluctuationLog[]>([]);
  const [loading, setLoading] = useState(true);
  const { fluctuationDetailsModalOpened } = useAppSelector((state) => state.rms!);
  const { fluctuation } = fluctuationDetailsModalOpened!;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await apiV1RmsService.get(`/fluctuation-logs/${fluctuation._id}`);
      setData(data);
    } catch {
      //
    }
    setLoading(false);
  }, [fluctuation._id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo<GridColDef<FluctuationLog>[]>(
    () => [
      {
        field: 'date',
        headerName: 'Hospedagem',
        flex: 1,
        type: 'date',
        valueGetter: ({ row }) => formatDate(row.fluctuation?.date, 'dd/MM/yyyy'),
      },
      {
        field: 'ratePlan.name',
        headerName: 'Tarifa',
        flex: 1,
        valueGetter: ({ row }) => row.fluctuation?.ratePlan?.name,
      },
      {
        field: 'roomtypeCategory.roomType.name',
        headerName: 'Acomodação',
        flex: 1,
        valueGetter: ({ row }) => row.fluctuation?.roomType?.name,
      },
      {
        field: 'createdAt',
        headerName: 'Horário de envio',
        flex: 1,
        type: 'date',
        valueGetter: ({ value }) => formatDate(value, 'dd/MM/yyyy - HH:mm:ss'),
      },
      {
        field: 'hasError',
        headerName: 'Erro',
        flex: 1,
        maxWidth: 100,
        type: 'boolean',
        renderCell: (params) =>
          params.value ? <Label color="error">Sim</Label> : <Label color="success">Não</Label>,
      },
      {
        field: 'fluctuation.indexes.occupation',
        headerAlign: 'center',
        headerName: 'OCC',
        flex: 1,
        type: 'number',
        valueGetter: ({ row }) => row.fluctuation?.indexes?.occupation || 0,
        renderCell: ({ value }) => formatPercentage(value, 2),
      },
      {
        field: 'fluctuation.appliedPrice',
        headerAlign: 'center',
        headerName: 'Valor aplicado',
        flex: 1,
        type: 'number',
        valueGetter: ({ row }) => row.fluctuation?.appliedPrice,
        renderCell: ({ value }) => (
          <Tooltip arrow placement="top" title="Último valor aplicado">
            <span>{formatCurrency(value, 2)}</span>
          </Tooltip>
        ),
      },
    ],
    [],
  );

  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <DataGrid<FluctuationLog>
            {...defaultTableProps}
            paginationMode="client"
            sortingMode="client"
            pageSize={20}
            rows={data}
            columns={columns}
            loading={loading}
          />
        </Paper>
      </CardContent>
    </Card>
  );
}
