import { RootState } from '@/redux';
import { ApiV1DashPickUpOldResponse } from '@/types/apiv1/responses/dashboards/api-v1-pick-up-responses';
import { ChartJSDataset } from '@/types/dashboards/charts/chart';
import { DashPickUpTableData } from '@/types/dashboards/pick-up-old';
import { WidgetSummaryCardData } from '@/types/dashboards/widgets/widget-summary';
import { apiV1DashboardService } from '@/utils/api-v1';
import {
  mountApiV1GetDashPickUpHotelRequest,
  mountApiV1GetDashPickUpHotelRitmRequest,
} from '@/utils/dashboards/pick-up-old';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashPickUpOldSliceState {
  loading: boolean;
  mode: 'normal' | 'ritm';
  hotels: {
    widgetData: WidgetSummaryCardData[];
    tableData: DashPickUpTableData;
    chartData: ChartJSDataset[];
  };
  channels: {
    widgetData: WidgetSummaryCardData[];
    tableData: DashPickUpTableData;
    chartData: ChartJSDataset[];
  };
  segments: {
    widgetData: WidgetSummaryCardData[];
    tableData: DashPickUpTableData;
    chartData: ChartJSDataset[];
  };
}

const initialTableData: DashPickUpTableData = {
  parentRows: [],
  parentCols: [],
  childCols: [],
};

const initialState: DashPickUpOldSliceState = {
  mode: 'normal',
  loading: false,
  hotels: {
    widgetData: [],
    tableData: initialTableData,
    chartData: [],
  },
  channels: {
    widgetData: [],
    tableData: initialTableData,
    chartData: [],
  },
  segments: {
    widgetData: [],
    tableData: initialTableData,
    chartData: [],
  },
};

const dashPickUpOldSlice = createSlice({
  name: 'dashPickUpOld',
  initialState,
  reducers: {
    setPickUpMode(state, action: PayloadAction<'normal' | 'ritm'>) {
      state.mode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashPickUpHotels.pending, (state) => {
        state.loading = true;
        state.hotels = initialState.hotels;
      })
      .addCase(
        fetchDashPickUpHotels.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpOldResponse>) => {
          state.loading = false;
          state.hotels.widgetData = action.payload.widgetData;
          state.hotels.chartData = action.payload.chartData;
          state.hotels.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashPickUpHotels.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchDashPickUpHotelsRitm.pending, (state) => {
        state.loading = true;
        state.hotels = initialState.hotels;
      })
      .addCase(
        fetchDashPickUpHotelsRitm.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpOldResponse>) => {
          state.loading = false;
          state.hotels.widgetData = action.payload.widgetData;
          state.hotels.chartData = action.payload.chartData;
          state.hotels.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashPickUpHotelsRitm.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchDashPickUpSegments.pending, (state) => {
        state.loading = true;
        state.segments = initialState.segments;
      })
      .addCase(
        fetchDashPickUpSegments.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpOldResponse>) => {
          state.loading = false;
          state.segments.widgetData = action.payload.widgetData;
          state.segments.chartData = action.payload.chartData;
          state.segments.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashPickUpSegments.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchDashPickUpSegmentsRitm.pending, (state) => {
        state.loading = true;
        state.segments = initialState.segments;
      })
      .addCase(
        fetchDashPickUpSegmentsRitm.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpOldResponse>) => {
          state.loading = false;
          state.segments.widgetData = action.payload.widgetData;
          state.segments.chartData = action.payload.chartData;
          state.segments.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashPickUpSegmentsRitm.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchDashPickUpChannels.pending, (state) => {
        state.loading = true;
        state.channels = initialState.channels;
      })
      .addCase(
        fetchDashPickUpChannels.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpOldResponse>) => {
          state.loading = false;
          state.channels.widgetData = action.payload.widgetData;
          state.channels.chartData = action.payload.chartData;
          state.channels.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashPickUpChannels.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchDashPickUpChannelsRitm.pending, (state) => {
        state.loading = true;
        state.channels = initialState.channels;
      })
      .addCase(
        fetchDashPickUpChannelsRitm.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpOldResponse>) => {
          state.loading = false;
          state.channels.widgetData = action.payload.widgetData;
          state.channels.chartData = action.payload.chartData;
          state.channels.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashPickUpChannelsRitm.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const fetchDashPickUpHotels = createAsyncThunk<
  ApiV1DashPickUpOldResponse,
  void,
  { state: RootState }
>('pickUpOld/fetchDashPickUpHotels', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, pickUpOldFilters } = getState().filters;

    const { index } = commonFilters;

    const params = mountApiV1GetDashPickUpHotelRequest(index, pickUpOldFilters);

    const response = await apiV1DashboardService.get<ApiV1DashPickUpOldResponse>(
      `/pick-up-old/hotels?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashPickUpHotelsRitm = createAsyncThunk<
  ApiV1DashPickUpOldResponse,
  void,
  { state: RootState }
>('pickUpOld/fetchDashPickUpHotelsRitm', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, pickUpOldFilters } = getState().filters;
    const { index } = commonFilters;

    const params = mountApiV1GetDashPickUpHotelRitmRequest(index, pickUpOldFilters);

    const response = await apiV1DashboardService.get<ApiV1DashPickUpOldResponse>(
      `/pick-up-old/hotels-ritm?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashPickUpSegments = createAsyncThunk<
  ApiV1DashPickUpOldResponse,
  void,
  { state: RootState }
>('pickUpOld/fetchDashPickUpSegments', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, pickUpOldFilters } = getState().filters;

    const { index, segments = [] } = commonFilters;

    if (segments.length === 0) {
      throw new Error('No segments selected');
    }

    const params = mountApiV1GetDashPickUpHotelRequest(index, pickUpOldFilters, segments);

    const response = await apiV1DashboardService.get<ApiV1DashPickUpOldResponse>(
      `/pick-up-old/segments?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashPickUpSegmentsRitm = createAsyncThunk<
  ApiV1DashPickUpOldResponse,
  void,
  { state: RootState }
>('pickUpOld/fetchDashPickUpSegmentsRitm', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, pickUpOldFilters } = getState().filters;

    const { index, segments = [] } = commonFilters;

    if (segments.length === 0) {
      throw new Error('No segments selected');
    }

    const params = mountApiV1GetDashPickUpHotelRitmRequest(index, pickUpOldFilters, segments);

    const response = await apiV1DashboardService.get<ApiV1DashPickUpOldResponse>(
      `/pick-up-old/segments-ritm?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashPickUpChannels = createAsyncThunk<
  ApiV1DashPickUpOldResponse,
  void,
  { state: RootState }
>('pickUpOld/fetchDashPickUpChannels', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, pickUpOldFilters } = getState().filters;

    const { index, channels = [] } = commonFilters;

    if (channels.length === 0) {
      throw new Error('No channels selected');
    }

    const params = mountApiV1GetDashPickUpHotelRequest(index, pickUpOldFilters, [], channels);

    const response = await apiV1DashboardService.get<ApiV1DashPickUpOldResponse>(
      `/pick-up-old/channels?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashPickUpChannelsRitm = createAsyncThunk<
  ApiV1DashPickUpOldResponse,
  void,
  { state: RootState }
>('pickUpOld/fetchDashPickUpChannelsRitm', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, pickUpOldFilters } = getState().filters;

    const { index, channels } = commonFilters;

    if (channels.length === 0) {
      throw new Error('No channels selected');
    }

    const params = mountApiV1GetDashPickUpHotelRitmRequest(index, pickUpOldFilters, [], channels);

    const response = await apiV1DashboardService.get<ApiV1DashPickUpOldResponse>(
      `/pick-up-old/channels-ritm?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const { setPickUpMode } = dashPickUpOldSlice.actions;
export default dashPickUpOldSlice.reducer;
