import { RootState } from '@/redux';
import { DashHomeV3Data, DashHomeV3Indexes } from '@/types/dashboards/home-v3';
import { DashGroup } from '@/types/enums/dashboards/dash-group.enum';
import { Filters } from '@/types/general/filters';
import { apiV1DashboardService } from '@/utils/api-v1';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashHomeV3SliceState {
  summary: {
    loadingTable: boolean;
    tableData: DashHomeV3Data[];
    loadingWidgetData: boolean;
    widgetsData: DashHomeV3Indexes;
    // LY
    loadingTableLY: boolean;
    loadingWidgetDataLY: boolean;
    tableDataLY: DashHomeV3Data[];
    widgetsDataLY: DashHomeV3Indexes;
    // LM
    loadingTableLM: boolean;
    tableDataLM: DashHomeV3Data[];
    loadingWidgetDataLM: boolean;
    widgetsDataLM: DashHomeV3Indexes;
    // GOAL TABLES:
    loadingTableGoals: boolean;
    tableDataGoals: DashHomeV3Data[];
    loadingTableSuperGoals: boolean;
    tableDataSuperGoals: DashHomeV3Data[];
    // GOAL WIDGETS:
    loadingWidgetDataGoals: boolean;
    widgetsDataGoals: DashHomeV3Indexes;
    loadingWidgetDataSuperGoals: boolean;
    widgetsDataSuperGoals: DashHomeV3Indexes;
  };
  details: {
    loadingTable: boolean;
    tableData: DashHomeV3Data[];
    // LY
    loadingTableLY: boolean;
    tableDataLY: DashHomeV3Data[];
    // LM
    loadingTableLM: boolean;
    tableDataLM: DashHomeV3Data[];
    // GOAL TABLES:
    loadingTableGoals: boolean;
    tableDataGoals: DashHomeV3Data[];
    loadingTableSuperGoals: boolean;
    tableDataSuperGoals: DashHomeV3Data[];
  };
}

const initialState: DashHomeV3SliceState = {
  summary: {
    loadingTable: false,
    tableData: [],
    loadingTableLY: false,
    tableDataLY: [],
    loadingTableLM: false,
    tableDataLM: [],
    loadingWidgetData: false,
    widgetsData: {},
    widgetsDataGoals: {},
    widgetsDataSuperGoals: {},
    widgetsDataLY: {},
    widgetsDataLM: {},
    loadingWidgetDataLY: false,
    loadingWidgetDataLM: false,
    loadingWidgetDataGoals: false,
    loadingWidgetDataSuperGoals: false,
    loadingTableGoals: false,
    tableDataGoals: [],
    loadingTableSuperGoals: false,
    tableDataSuperGoals: [],
  },
  details: {
    loadingTable: false,
    tableData: [],
    loadingTableLY: false,
    tableDataLY: [],
    loadingTableLM: false,
    tableDataLM: [],
    loadingTableGoals: false,
    tableDataGoals: [],
    loadingTableSuperGoals: false,
    tableDataSuperGoals: [],
  },
};

const homeV3Slice = createSlice({
  name: 'homeV3',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeV3SummaryTable.pending, (state) => {
        state.summary.loadingTable = true;
      })
      .addCase(
        fetchHomeV3SummaryTable.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.summary.loadingTable = false;
          state.summary.tableData = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryTable.rejected, (state) => {
        state.summary.loadingTable = false;
      })
      .addCase(fetchHomeV3SummaryTableLY.pending, (state) => {
        state.summary.loadingTableLY = true;
      })
      .addCase(
        fetchHomeV3SummaryTableLY.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.summary.loadingTableLY = false;
          state.summary.tableDataLY = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryTableLY.rejected, (state) => {
        state.summary.loadingTableLY = false;
      })
      .addCase(fetchHomeV3SummaryTableLM.pending, (state) => {
        state.summary.loadingTableLM = true;
      })
      .addCase(
        fetchHomeV3SummaryTableLM.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.summary.loadingTableLM = false;
          state.summary.tableDataLM = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryTableLM.rejected, (state) => {
        state.summary.loadingTableLM = false;
      })
      .addCase(fetchHomeV3SummaryWidgets.pending, (state) => {
        state.summary.loadingWidgetData = true;
      })
      .addCase(
        fetchHomeV3SummaryWidgets.fulfilled,
        (state, action: PayloadAction<DashHomeV3Indexes>) => {
          state.summary.loadingWidgetData = false;
          state.summary.widgetsData = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryWidgets.rejected, (state) => {
        state.summary.loadingWidgetData = false;
      })
      .addCase(fetchHomeV3SummaryWidgetsLY.pending, (state) => {
        state.summary.loadingWidgetDataLY = true;
      })
      .addCase(
        fetchHomeV3SummaryWidgetsLY.fulfilled,
        (state, action: PayloadAction<DashHomeV3Indexes>) => {
          state.summary.loadingWidgetDataLY = false;
          state.summary.widgetsDataLY = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryWidgetsLY.rejected, (state) => {
        state.summary.loadingWidgetDataLY = false;
      })
      .addCase(fetchHomeV3SummaryWidgetsLM.pending, (state) => {
        state.summary.loadingWidgetDataLM = true;
      })
      .addCase(
        fetchHomeV3SummaryWidgetsLM.fulfilled,
        (state, action: PayloadAction<DashHomeV3Indexes>) => {
          state.summary.loadingWidgetDataLM = false;
          state.summary.widgetsDataLM = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryWidgetsLM.rejected, (state) => {
        state.summary.loadingWidgetDataLM = false;
      })
      .addCase(fetchHomeV3SummaryTableGoals.pending, (state) => {
        state.summary.loadingTableGoals = true;
      })
      .addCase(
        fetchHomeV3SummaryTableGoals.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.summary.loadingTableGoals = false;
          state.summary.tableDataGoals = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryTableGoals.rejected, (state) => {
        state.summary.loadingTableGoals = false;
      })
      .addCase(fetchHomeV3SummaryTableSuperGoals.pending, (state) => {
        state.summary.loadingTableSuperGoals = true;
      })
      .addCase(
        fetchHomeV3SummaryTableSuperGoals.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.summary.loadingTableSuperGoals = false;
          state.summary.tableDataSuperGoals = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryTableSuperGoals.rejected, (state) => {
        state.summary.loadingTableSuperGoals = false;
      })
      .addCase(fetchHomeV3SummaryWidgetsGoals.pending, (state) => {
        state.summary.loadingWidgetDataGoals = true;
      })
      .addCase(
        fetchHomeV3SummaryWidgetsGoals.fulfilled,
        (state, action: PayloadAction<DashHomeV3Indexes>) => {
          state.summary.loadingWidgetDataGoals = false;
          state.summary.widgetsDataGoals = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryWidgetsGoals.rejected, (state) => {
        state.summary.loadingWidgetDataGoals = false;
      })
      .addCase(fetchHomeV3SummaryWidgetsSuperGoals.pending, (state) => {
        state.summary.loadingWidgetDataSuperGoals = true;
      })
      .addCase(
        fetchHomeV3SummaryWidgetsSuperGoals.fulfilled,
        (state, action: PayloadAction<DashHomeV3Indexes>) => {
          state.summary.loadingWidgetDataSuperGoals = false;
          state.summary.widgetsDataSuperGoals = action.payload;
        },
      )
      .addCase(fetchHomeV3SummaryWidgetsSuperGoals.rejected, (state) => {
        state.summary.loadingWidgetDataSuperGoals = false;
      })
      .addCase(fetchHomeV3DetailsTable.pending, (state) => {
        state.details.loadingTable = true;
      })
      .addCase(
        fetchHomeV3DetailsTable.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.details.loadingTable = false;
          state.details.tableData = action.payload;
        },
      )
      .addCase(fetchHomeV3DetailsTable.rejected, (state) => {
        state.details.loadingTable = false;
      })
      .addCase(fetchHomeV3DetailsTableLY.pending, (state) => {
        state.details.loadingTableLY = true;
      })
      .addCase(
        fetchHomeV3DetailsTableLY.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.details.loadingTableLY = false;
          state.details.tableDataLY = action.payload;
        },
      )
      .addCase(fetchHomeV3DetailsTableLY.rejected, (state) => {
        state.details.loadingTableLY = false;
      })
      .addCase(fetchHomeV3DetailsTableLM.pending, (state) => {
        state.details.loadingTableLM = true;
      })
      .addCase(
        fetchHomeV3DetailsTableLM.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.details.loadingTableLM = false;
          state.details.tableDataLM = action.payload;
        },
      )
      .addCase(fetchHomeV3DetailsTableLM.rejected, (state) => {
        state.details.loadingTableLM = false;
      })
      .addCase(fetchHomeV3DetailsTableGoals.pending, (state) => {
        state.details.loadingTableGoals = true;
      })
      .addCase(
        fetchHomeV3DetailsTableGoals.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.details.loadingTableGoals = false;
          state.details.tableDataGoals = action.payload;
        },
      )
      .addCase(fetchHomeV3DetailsTableGoals.rejected, (state) => {
        state.details.loadingTableGoals = false;
      })
      .addCase(fetchHomeV3DetailsTableSuperGoals.pending, (state) => {
        state.details.loadingTableSuperGoals = true;
      })
      .addCase(
        fetchHomeV3DetailsTableSuperGoals.fulfilled,
        (state, action: PayloadAction<DashHomeV3Data[]>) => {
          state.details.loadingTableSuperGoals = false;
          state.details.tableDataSuperGoals = action.payload;
        },
      )
      .addCase(fetchHomeV3DetailsTableSuperGoals.rejected, (state) => {
        state.details.loadingTableSuperGoals = false;
      });
  },
});

export const fetchHomeV3SummaryTable = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3SummaryTable', async (_, { rejectWithValue, getState }) => {
  try {
    const { data } = await apiV1DashboardService.get(
      `/home-summary/table?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryTableGoals = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3SummaryTableGoals', async (_, { rejectWithValue, getState }) => {
  try {
    const { showGoals } = getState().filters.commonFilters;

    if (!showGoals) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/table-goals?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryTableSuperGoals = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3SummaryTableSuperGoals', async (_, { rejectWithValue, getState }) => {
  try {
    const { showSuperGoals } = getState().filters.commonFilters;

    if (!showSuperGoals) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/table-super-goals?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryTableLY = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3SummaryTableLY', async (_, { rejectWithValue, getState }) => {
  try {
    const { showLastYear } = getState().filters.commonFilters;

    if (!showLastYear) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/table-ly?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryTableLM = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3SummaryTableLM', async (_, { rejectWithValue, getState }) => {
  try {
    const { showLastMonth } = getState().filters.commonFilters;

    if (!showLastMonth) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/table-lm?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryWidgets = createAsyncThunk<
  DashHomeV3Indexes,
  void,
  { state: RootState }
>('fetchHomeV3SummaryWidgets', async (_, { rejectWithValue, getState }) => {
  try {
    const { data } = await apiV1DashboardService.get(
      `/home-summary/widgets?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryWidgetsLY = createAsyncThunk<
  DashHomeV3Indexes,
  void,
  { state: RootState }
>('fetchHomeV3SummaryWidgetsLY', async (_, { rejectWithValue, getState }) => {
  try {
    const { showLastYear } = getState().filters.commonFilters;

    if (!showLastYear) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/widgets-ly?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryWidgetsLM = createAsyncThunk<
  DashHomeV3Indexes,
  void,
  { state: RootState }
>('fetchHomeV3SummaryWidgetsLM', async (_, { rejectWithValue, getState }) => {
  try {
    const { showLastMonth } = getState().filters.commonFilters;

    if (!showLastMonth) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/widgets-lm?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryWidgetsGoals = createAsyncThunk<
  DashHomeV3Indexes,
  void,
  { state: RootState }
>('fetchHomeV3SummaryWidgetsGoals', async (_, { rejectWithValue, getState }) => {
  try {
    const { showGoals } = getState().filters.commonFilters;

    if (!showGoals) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/widgets-goals?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3SummaryWidgetsSuperGoals = createAsyncThunk<
  DashHomeV3Indexes,
  void,
  { state: RootState }
>('fetchHomeV3SummaryWidgetsSuperGoals', async (_, { rejectWithValue, getState }) => {
  try {
    const { showSuperGoals } = getState().filters.commonFilters;

    if (!showSuperGoals) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-summary/widgets-super-goals?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3DetailsTable = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3DetailsTable', async (_, { rejectWithValue, getState }) => {
  try {
    const { data } = await apiV1DashboardService.get(
      `/home-details/table?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3DetailsTableLY = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3DetailsTableLY', async (_, { rejectWithValue, getState }) => {
  try {
    const { showLastYear } = getState().filters.commonFilters;

    if (!showLastYear) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-details/table-ly?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3DetailsTableLM = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3DetailsTableLM', async (_, { rejectWithValue, getState }) => {
  try {
    const { showLastMonth } = getState().filters.commonFilters;

    if (!showLastMonth) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-details/table-lm?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3DetailsTableGoals = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3DetailsTableGoals', async (_, { rejectWithValue, getState }) => {
  try {
    const { showGoals } = getState().filters.commonFilters;

    if (!showGoals) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-details/table-goals?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchHomeV3DetailsTableSuperGoals = createAsyncThunk<
  DashHomeV3Data[],
  void,
  { state: RootState }
>('fetchHomeV3DetailsTableSuperGoals', async (_, { rejectWithValue, getState }) => {
  try {
    const { showSuperGoals } = getState().filters.commonFilters;

    if (!showSuperGoals) {
      return [];
    }

    const { data } = await apiV1DashboardService.get(
      `/home-details/table-super-goals?${stringify(getHomeRequest(getState().filters), {
        arrayFormat: 'bracket',
      })}`,
    );

    return data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export default homeV3Slice.reducer;

function getHomeRequest(filters: Filters) {
  const { commonFilters } = filters;
  const {
    startDate,
    endDate,
    granularity,
    macroSegments = [],
    segmentGroups = [],
    segments = [],
    channelGroups = [],
    channels = [],
    indexes = [],
    reservationStatus,
    groups = [],
    groupFilterOption,
  } = commonFilters;

  const params: any = {
    startDate: formatDateApi(startDate),
    endDate: formatDateApi(endDate),
    granularity,
    indexes,
    status: reservationStatus,
    macroSegments: [],
    segmentGroups: [],
    segments: [],
    channelGroups: [],
    channels: [],
    groupFilterOption,
    groups,
  };

  if (groups.includes(DashGroup.MACRO_SEGMENTS)) {
    params.macroSegments = macroSegments.map((x) => x._id);
  }

  if (groups.includes(DashGroup.SEGMENT_GROUPS)) {
    params.segmentGroups = segmentGroups.map((x) => x._id);
  }

  if (groups.includes(DashGroup.SEGMENTS)) {
    params.segments = segments.map((x) => x._id);
  }

  if (groups.includes(DashGroup.CHANNEL_GROUPS)) {
    params.channelGroups = channelGroups.map((x) => x._id);
  }

  if (groups.includes(DashGroup.CHANNELS)) {
    params.channels = channels.map((x) => x._id);
  }

  return params;
}
