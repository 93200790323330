import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashGroup {
  // Possuem relacionamento direto na tabela revenue
  HOTELS = 'HOTELS',
  MACRO_SEGMENTS = 'MACRO_SEGMENTS',
  SEGMENT_GROUPS = 'SEGMENT_GROUPS',
  SEGMENTS = 'SEGMENTS',
  CHANNEL_GROUPS = 'CHANNEL_GROUPS',
  CHANNELS = 'CHANNELS',
  ROOM_TYPES = 'ROOM_TYPES',
  // Requer join/lookup:
  REGIONALS = 'REGIONALS',
  WALLETS = 'WALLETS',
  CITIES = 'CITIES',
}

export function getDashGroupHumanRead(value: DashGroup, plural = true): string {
  switch (value) {
    case DashGroup.HOTELS:
      return plural ? 'Hotéis' : 'Hotel';
    case DashGroup.MACRO_SEGMENTS:
      return plural ? 'Macro Segmentos' : 'Macro Segmento';
    case DashGroup.SEGMENT_GROUPS:
      return plural ? 'Grupos de segmentos' : 'Grupo de segmentos';
    case DashGroup.SEGMENTS:
      return plural ? 'Segmentos' : 'Segmento';
    case DashGroup.CHANNEL_GROUPS:
      return plural ? 'Grupos de canais' : 'Grupo de canais';
    case DashGroup.CHANNELS:
      return plural ? 'Canais' : 'Canal';
    case DashGroup.ROOM_TYPES:
      return plural ? 'Tipos de quartos' : 'Tipo de quarto';
    case DashGroup.REGIONALS:
      return plural ? 'Regionais' : 'Regional';
    case DashGroup.WALLETS:
      return plural ? 'Carteiras' : 'Carteira';
    case DashGroup.CITIES:
      return plural ? 'Cidades' : 'Cidade';
    default:
      return value;
  }
}

/**
 * Retorna o nome do grupo (usado no json de retorno da api) conforme o grupo
 */
export function getDashGroupFieldName(group: DashGroup): string {
  switch (group) {
    case DashGroup.HOTELS:
      return 'hotel';
    case DashGroup.MACRO_SEGMENTS:
      return 'macroSegment';
    case DashGroup.SEGMENTS:
      return 'segment';
    case DashGroup.SEGMENT_GROUPS:
      return 'segmentGroup';
    case DashGroup.CHANNELS:
      return 'channel';
    case DashGroup.CHANNEL_GROUPS:
      return 'channelGroup';
    case DashGroup.REGIONALS:
      return 'regional';
    case DashGroup.WALLETS:
      return 'wallet';
    case DashGroup.CITIES:
      return 'city';
    case DashGroup.ROOM_TYPES:
      return 'roomType';
    default:
      throw new Error(`Nome do grupo ${getDashGroupHumanRead(group)} não implementado.`);
  }
}

export function getDashGroupFromFieldName(fieldName: string): DashGroup {
  switch (fieldName) {
    case 'hotel':
      return DashGroup.HOTELS;
    case 'macroSegment':
      return DashGroup.MACRO_SEGMENTS;
    case 'segmentGroup':
      return DashGroup.SEGMENT_GROUPS;
    case 'segment':
      return DashGroup.SEGMENTS;
    case 'channel':
      return DashGroup.CHANNELS;
    case 'channelGroup':
      return DashGroup.CHANNEL_GROUPS;
    case 'regional':
      return DashGroup.REGIONALS;
    case 'wallet':
      return DashGroup.WALLETS;
    case 'city':
      return DashGroup.CITIES;
    case 'roomType':
      return DashGroup.ROOM_TYPES;
    default:
      throw new Error(`Nome do campo ${fieldName} não implementado.`);
  }
}

/**
 * @deprecated aplicar todas os grupos nos relatórios que ainda não possuem (requer adaptação na api)
 */
export const oldDashGroups: GenericIdName[] = [
  DashGroup.HOTELS,
  DashGroup.SEGMENT_GROUPS,
  DashGroup.SEGMENTS,
  DashGroup.CHANNELS,
  DashGroup.REGIONALS,
  DashGroup.WALLETS,
  DashGroup.CITIES,
].map((x) => ({
  _id: x,
  name: getDashGroupHumanRead(x),
}));

export const allDashGroups: GenericIdName[] = Object.values(DashGroup).map((x) => ({
  _id: x,
  name: getDashGroupHumanRead(x),
}));
