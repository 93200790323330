import { ApiV1UpdateUserSessionRequest } from '@/types/apiv1/requests/api-v1-update-user-session-request';
import {
  ApiV1Login2FAResponse,
  ApiV1LoginResponse,
  ApiV1ProfileResponse,
} from '@/types/apiv1/responses/api-v1-auth-responses';
import { UserSessionMode } from '@/types/enums/user-session-mode.enum';
import { UserSession, UserSessionDropdowns } from '@/types/models/user-session';
import { apiV1AuthService } from '@/utils/api-v1';
import { sanitizeUserSessionDropdowns } from '@/utils/user-session';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

interface AuthSliceState {
  isLogged: boolean;
  bootingApp: boolean;
  userSessionDropdowns: UserSessionDropdowns;
  userSession: UserSession;
  submitingUserSession: boolean;
}

const initialState: AuthSliceState = {
  isLogged: false,
  bootingApp: true,
  userSessionDropdowns: {
    hotels: [],
    regionals: [],
    wallets: [],
    cities: [],
  },
  userSession: {
    _id: '',
    user: {} as any,
    mode: UserSessionMode.HOTEL,
    hotels: [],
    wallets: [],
    regionals: [],
    cities: [],
    lastHotelSelected: {
      _id: '',
      name: '',
      slug: '',
      activeRooms: 0,
      primaryColor: '',
      concurrencyEnabled: false,
      rmsEnabled: false,
      city: {
        _id: '',
        name: '',
      },
    },
    createdAt: '',
    updatedAt: '',
  },
  submitingUserSession: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAppLoaded: (state) => {
      state.bootingApp = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        // state.lastApiV1Error = null;
      })
      .addCase(
        loginUser.fulfilled,
        (state, action: PayloadAction<ApiV1LoginResponse | ApiV1Login2FAResponse>) => {
          if (action.payload.hasOwnProperty('token')) {
            // Logado sem 2FA
            const { token, userSessionDropdowns, userSession } =
              action.payload as ApiV1LoginResponse;

            state.userSession = userSession;
            state.userSessionDropdowns = sanitizeUserSessionDropdowns(
              userSessionDropdowns,
              userSession,
            );
            state.isLogged = true;
            localStorage.setItem('@token', token);
          }
        },
      )
      .addCase(logoutUser.fulfilled, (state) => {
        state = initialState;
        localStorage.clear();
        window.location.href = '/auth/login';
      })
      .addCase(loginUser.rejected, (state, action) => {
        // state.lastApiV1Error = action.payload as ApiV1ErrorResponse;
      })
      .addCase(fetchProfileData.pending, (state) => {
        // state.lastApiV1Error = null;
      })
      .addCase(fetchProfileData.fulfilled, (state, action: PayloadAction<ApiV1ProfileResponse>) => {
        const { userSessionDropdowns, userSession } = action.payload;
        state.userSession = userSession;
        state.userSessionDropdowns = sanitizeUserSessionDropdowns(
          userSessionDropdowns,
          userSession,
        );
        state.bootingApp = false;
        state.isLogged = true;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state = {
          ...initialState,
          // lastApiV1Error: action.payload as ApiV1ErrorResponse,
          bootingApp: false,
        };
        localStorage.clear();
      })
      .addCase(updateUserSession.pending, (state) => {
        // state.lastApiV1Error = null;
        state.submitingUserSession = true;
      })
      .addCase(updateUserSession.fulfilled, (state, action: PayloadAction<UserSession>) => {
        state.userSession = action.payload;
        state.submitingUserSession = false;
        state.userSessionDropdowns = sanitizeUserSessionDropdowns(
          state.userSessionDropdowns,
          state.userSession,
        );
      })
      .addCase(updateUserSession.rejected, (state, action) => {
        state.submitingUserSession = false;
        // state.lastApiV1Error = action.payload as ApiV1ErrorResponse;
      });
  },
});

export const loginUser = createAsyncThunk<
  ApiV1LoginResponse | ApiV1Login2FAResponse,
  {
    email: string;
    password: string;
    recaptchaToken: string;
  },
  { state: RootState }
>('auth/loginUser', async (params, { rejectWithValue }) => {
  try {
    const response = await apiV1AuthService.post<ApiV1LoginResponse | ApiV1Login2FAResponse>(
      '/login',
      params,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const logoutUser = createAsyncThunk('auth/logoutAsync', async (_, { rejectWithValue }) => {
  try {
    await apiV1AuthService.delete('logout');
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchProfileData = createAsyncThunk<ApiV1ProfileResponse, void, { state: RootState }>(
  'auth/fetchProfileData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiV1AuthService.get<ApiV1ProfileResponse>('/profile');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateUserSession = createAsyncThunk<
  UserSession,
  ApiV1UpdateUserSessionRequest,
  { state: RootState }
>('user-session', async (params, { rejectWithValue }) => {
  try {
    const response = await apiV1AuthService.patch<UserSession>('/user-session', params);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const { setAppLoaded } = authSlice.actions;
export default authSlice.reducer;
