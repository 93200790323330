import Iconify from '@/components/Iconify';
import { NavListProps } from '@/components/nav-section/type';
import { CONCURRENCY_ICON, RMS_ICON } from '@/config/icons';
import { UserPermission } from '@/types/enums/user-permission.enum';

type NavConfig = {
  subheader: string;
  items: NavListProps[];
};

const navConfig: NavConfig[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Home (v1)',
        path: '/',
        icon: <Iconify icon="carbon:home" width={20} height={20} />,
      },
      {
        title: 'Home (v2)',
        path: '/v2',
        icon: <Iconify icon="tabler:home-dot" width={20} height={20} />,
      },
      {
        title: 'Home (v3)',
        path: '/v3',
        icon: <Iconify icon="tabler:home-dot" width={20} height={20} />,
      },
    ],
  },
  {
    subheader: 'Relatórios',
    items: [
      {
        title: 'Dashboards',
        path: '/dashboards',
        icon: <Iconify icon="bi:graph-up" width={20} height={20} />,
        children: [
          {
            title: 'Ranking',
            path: '/dashboards/ranking',
            permission: UserPermission.RANKING_READ,
          },
          {
            title: 'Pick-Up',
            path: '/dashboards/pick-up',
            permission: UserPermission.PICK_UP_READ,
            children: [
              { title: 'Hotéis', path: '/dashboards/pick-ups/hotels' },
              { title: 'Segmentos', path: '/dashboards/pick-ups/segments' },
              { title: 'Canais', path: '/dashboards/pick-ups/channels' },
              { title: 'V2 (beta)', path: '/dashboards/pick-ups-v2/default' },
            ],
          },
          {
            title: 'Comparativos',
            path: '/dashboards/benchmark',
            permission: UserPermission.BENCHMARK_READ,
          },
          {
            title: 'Metas de emissão',
            path: '/dashboards/emissions',
            permission: UserPermission.GOAL_EMISSION_READ,
            children: [
              { title: 'Hotéis', path: '/dashboards/emissions/hotels' },
              { title: 'Segmentos', path: '/dashboards/emissions/segments' },
              { title: 'Canais', path: '/dashboards/emissions/channels' },
            ],
          },
        ],
      },
    ],
  },
  {
    subheader: 'RMS',
    items: [
      {
        title: 'RMS',
        path: '/rms',
        icon: <Iconify icon={RMS_ICON} width={20} height={20} />,
        permission: UserPermission.RMS_READ,
        children: [
          {
            title: 'Flutuações (lista)',
            path: '/rms/fluctuations',
          },
          {
            title: 'Flutuações (calendário)',
            path: '/rms/fluctuations-calendar',
          },
          {
            title: 'GRI',
            path: '/gri',
            children: [
              {
                title: 'Relatório GRI',
                path: '/rms/gri',
              },
              {
                title: 'Classificações GRI',
                path: '/rms/gri/category',
              },
            ],
          },
          {
            title: 'Regras de flutuação',
            path: '/rms/fluctuation-rules',
          },
          {
            title: 'Acomodações',
            path: '/rms/room-types',
          },
          {
            title: 'Planos tarifários',
            path: '/rms/rate-plans',
          },
          {
            title: 'Linhas Tarifárias',
            path: '/rms/tariff-lines',
          },
          {
            title: 'Logs RMS',
            path: '/logs',
            children: [
              {
                title: 'Tarifas enviadas p/ Omnibees',
                path: '/rms/fluctuation-logs',
              },
              {
                title: 'Alterações efetuadas',
                path: '/rms/action-logs',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    subheader: 'Concorrência',
    items: [
      {
        title: 'Concorrência',
        path: '/concurrency',
        icon: <Iconify icon={CONCURRENCY_ICON} width={20} height={20} />,
        permission: UserPermission.CONCURRENCY_READ,
        children: [
          {
            title: 'Análise resumida',
            path: '/concurrency/analysis-summary',
          },
          {
            title: 'Análise detalhada',
            path: '/concurrency/concurrent-prices',
          },
          {
            title: 'Cadastro concorrentes',
            path: '/concurrency/concurrents',
          },
          {
            title: 'Mapeamento acomodações',
            path: '/concurrency/room-type-mapping',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Sistema',
    items: [
      {
        title: 'Marketing',
        path: '/mkt',
        icon: <Iconify icon="nimbus:marketing" width={20} height={20} />,
        permission: UserPermission.MKT_READ,
        children: [
          {
            title: 'Campanhas',
            path: '/campaigns',
            permission: UserPermission.MKT_READ,
          },
          {
            title: 'Google ADs',
            path: '/google-ads',
            children: [
              {
                title: 'Contas campanhas',
                path: '/google-ads/accounts',
              },
              {
                title: 'Contas desenvolvimento',
                path: '/google-ads/dev-accounts',
              },
            ],
          },
          {
            title: 'Meta',
            path: '/meta',
            children: [
              {
                title: 'Contas campanhas',
                path: '/meta/accounts',
              },
              {
                title: 'Contas desenvolvimento',
                path: '/meta/dev-accounts',
              },
            ],
          },
          {
            title: 'TikTok',
            path: '/tiktok',
            children: [
              {
                title: 'Contas campanhas',
                path: '/tiktok/accounts',
              },
              {
                title: 'Contas desenvolvimento',
                path: '/tiktok/dev-accounts',
              },
            ],
          },
        ],
      },
      {
        title: 'Governança',
        path: '/housekeeping',
        icon: <Iconify icon="carbon:clean" width={20} height={20} />,
        permission: UserPermission.HOUSEKEEPING_READ,
        children: [
          {
            title: 'Camareiras',
            path: '/housekeepers',
            permission: UserPermission.HOUSEKEEPING_READ,
          },
          {
            title: 'Gestão de limpeza',
            path: '/cleaning-management',
            permission: UserPermission.HOUSEKEEPING_READ,
          },
        ],
      },
      {
        title: 'Cadastros',
        path: '/management',
        icon: <Iconify icon="carbon:id-management" width={20} height={20} />,
        children: [
          {
            title: 'Metas Check-in',
            path: '/goals',
            permission: UserPermission.GOAL_READ,
          },
          {
            title: 'Macro Segmentos',
            path: '/macro-segments',
            permission: UserPermission.MACRO_SEGMENT_READ,
          },
          {
            title: 'Grupos de segmentos',
            path: '/segment-groups',
            permission: UserPermission.SEGMENT_GROUP_READ,
          },
          {
            title: 'Segmentos',
            path: '/segments',
            permission: UserPermission.SEGMENT_READ,
          },
          {
            title: 'Grupos de Canais',
            path: '/channel-groups',
            permission: UserPermission.CHANNEL_GROUP_READ,
          },
          {
            title: 'Canais',
            path: '/channels',
            permission: UserPermission.CHANNEL_READ,
          },
          {
            title: 'Quartos',
            path: '/rooms',
            permission: UserPermission.ROOM_READ,
          },
          {
            title: 'Feriados/Eventos',
            path: '/holidays',
            permission: UserPermission.HOLIDAY_READ,
          },
        ],
      },
      {
        title: 'Administrativo',
        path: '/admin',
        icon: <Iconify icon="ic:baseline-lock" width={20} height={20} />,
        children: [
          {
            title: 'Hotéis',
            path: '/hotels',
            permission: UserPermission.HOTEL_READ,
          },
          {
            title: 'Regionais',
            path: '/regionals',
            permission: UserPermission.REGIONAL_READ,
          },
          {
            title: 'Carteiras',
            path: '/wallets',
            permission: UserPermission.WALLET_READ,
          },
          {
            title: 'Usuários',
            path: '/users',
            permission: UserPermission.USER_READ,
          },
          {
            title: 'Perfis de Usuários',
            path: '/user-roles',
            permission: UserPermission.USER_ROLE_READ,
          },
          {
            title: 'Usabilidade',
            path: '/app-usage',
            permission: UserPermission.APP_USAGE_READ,
          },
          {
            title: 'Logs',
            path: '/logs',
            permission: UserPermission.LOGS_READ,
            children: [
              {
                title: 'Importações HITS',
                path: '/logs/revenue-jobs',
              },
              {
                title: 'Reprocessamento de Pick-Up',
                path: '/logs/pick-up-jobs',
              },
              { title: 'Logs de Integração', path: '/logs/out' },
              {
                title: 'Ações de usuários (GERAL)',
                path: '/logs/in',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default navConfig;
