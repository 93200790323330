export enum UserPermission {
  USER_READ = 'USER_READ',
  USER_WRITE = 'USER_WRITE',
  HOTEL_READ = 'HOTEL_READ',
  HOTEL_WRITE = 'HOTEL_WRITE',
  HOLIDAY_READ = 'HOLIDAY_READ',
  HOLIDAY_WRITE = 'HOLIDAY_WRITE',
  USER_ROLE_READ = 'USER_ROLE_READ',
  USER_ROLE_WRITE = 'USER_ROLE_WRITE',
  RMS_READ = 'RMS_READ',
  RMS_WRITE = 'RMS_WRITE',
  RMS_LOGS_READ = 'RMS_LOGS_READ',
  GOAL_READ = 'GOAL_READ',
  GOAL_WRITE = 'GOAL_WRITE',
  REGIONAL_READ = 'REGIONAL_READ',
  REGIONAL_WRITE = 'REGIONAL_WRITE',
  SEGMENT_GROUP_READ = 'SEGMENT_GROUP_READ',
  SEGMENT_GROUP_WRITE = 'SEGMENT_GROUP_WRITE',
  CHANNEL_READ = 'CHANNEL_READ',
  CHANNEL_WRITE = 'CHANNEL_WRITE',
  CHANNEL_GROUP_READ = 'CHANNEL_GROUP_READ',
  CHANNEL_GROUP_WRITE = 'CHANNEL_GROUP_WRITE',
  SEGMENT_READ = 'SEGMENT_READ',
  SEGMENT_WRITE = 'SEGMENT_WRITE',
  LOGS_READ = 'LOGS_READ',
  CONCURRENCY_READ = 'CONCURRENCY_READ',
  CONCURRENCY_WRITE = 'CONCURRENCY_WRITE',
  GOAL_EMISSION_READ = 'GOAL_EMISSION_READ',
  GOAL_EMISSION_WRITE = 'GOAL_EMISSION_WRITE',
  ROOM_READ = 'ROOM_READ',
  PICK_UP_READ = 'PICK_UP_READ', // Dash pick up
  BENCHMARK_READ = 'BENCHMARK_READ', // Dash comparativos
  RANKING_READ = 'RANKING_READ', // Dash ranking
  PICKUP_JOB_READ = 'PICKUP_JOB_READ',
  PICKUP_JOB_WRITE = 'PICKUP_JOB_WRITE',
  MKT_READ = 'MKT_READ',
  MKT_WRITE = 'MKT_WRITE',
  WALLET_READ = 'WALLET_READ',
  WALLET_WRITE = 'WALLET_WRITE',
  REVENUE_JOB_READ = 'REVENUE_JOB_READ',
  REVENUE_JOB_WRITE = 'REVENUE_JOB_WRITE',
  HOUSEKEEPING_READ = 'HOUSEKEEPING_READ',
  HOUSEKEEPING_WRITE = 'HOUSEKEEPING_WRITE',
  APP_USAGE_READ = 'APP_USAGE_READ',
  GRI_READ = 'GRI_READ',
  GRI_WRITE = 'GRI_WRITE',
  MACRO_SEGMENT_READ = 'MACRO_SEGMENT_READ',
  MACRO_SEGMENT_WRITE = 'MACRO_SEGMENT_WRITE',
  PUSH_NOTIFICATIONS_WRITE = 'PUSH_NOTIFICATIONS_WRITE',
}

export const getUserPermissionHumaRead = (permission: UserPermission) => {
  switch (permission) {
    case UserPermission.USER_READ:
      return 'Usuários (leitura)';
    case UserPermission.USER_WRITE:
      return 'Usuários (escrita)';
    case UserPermission.HOTEL_READ:
      return 'Hotéis (leitura)';
    case UserPermission.HOTEL_WRITE:
      return 'Hotéis (escrita)';
    case UserPermission.USER_ROLE_READ:
      return 'Perfis de usuário (leitura)';
    case UserPermission.USER_ROLE_WRITE:
      return 'Perfis de usuário (escrita)';
    case UserPermission.RMS_READ:
      return 'RMS (leitura)';
    case UserPermission.RMS_WRITE:
      return 'RMS (escrita)';
    case UserPermission.RMS_LOGS_READ:
      return 'RMS (logs))';
    case UserPermission.GOAL_READ:
      return 'Metas checkinnn (leitura)';
    case UserPermission.GOAL_WRITE:
      return 'Metas checkinnn (escrita)';
    case UserPermission.REGIONAL_READ:
      return 'Regionais (leitura)';
    case UserPermission.REGIONAL_WRITE:
      return 'Regionais (escrita)';
    case UserPermission.SEGMENT_GROUP_READ:
      return 'Grupos de segmentos (leitura)';
    case UserPermission.SEGMENT_GROUP_WRITE:
      return 'Grupos de segmentos (escrita)';
    case UserPermission.LOGS_READ:
      return 'Acesso aos logs do sistema';
    case UserPermission.CONCURRENCY_READ:
      return 'Concorrência (leitura)';
    case UserPermission.CONCURRENCY_WRITE:
      return 'Concorrência (escrita)';
    case UserPermission.GOAL_EMISSION_READ:
      return 'Metas emissão (leitura)';
    case UserPermission.GOAL_EMISSION_WRITE:
      return 'Metas emissão (escrita)';
    case UserPermission.BENCHMARK_READ:
      return 'Dash Comparativos';
    case UserPermission.RANKING_READ:
      return 'Dash Ranking';
    case UserPermission.PICK_UP_READ:
      return 'Dash PickUp';
    case UserPermission.SEGMENT_READ:
      return 'Segmentos (leitura)';
    case UserPermission.SEGMENT_WRITE:
      return 'Segmentos (escrita)';
    case UserPermission.CHANNEL_READ:
      return 'Canais (leitura)';
    case UserPermission.CHANNEL_WRITE:
      return 'Canais (escrita)';
    case UserPermission.ROOM_READ:
      return 'Quartos (leitura)';
    case UserPermission.PICKUP_JOB_READ:
      return 'Reprocessamento de pick ups (leitura)';
    case UserPermission.PICKUP_JOB_WRITE:
      return 'Reprocessamento de pick ups (escrita)';
    case UserPermission.MKT_READ:
      return 'MKT (leitura)';
    case UserPermission.MKT_WRITE:
      return 'MKT (escrita)';
    case UserPermission.WALLET_READ:
      return 'Carteira (leitura)';
    case UserPermission.WALLET_WRITE:
      return 'Carteira (escrita)';
    case UserPermission.REVENUE_JOB_READ:
      return 'Importação manual (leitura)';
    case UserPermission.REVENUE_JOB_WRITE:
      return 'Importação manual (escrita)';
    case UserPermission.HOUSEKEEPING_READ:
      return 'Housekeeping (leitura)';
    case UserPermission.HOUSEKEEPING_WRITE:
      return 'Housekeeping (escrita)';
    case UserPermission.HOLIDAY_READ:
      return 'Feriados (leitura)';
    case UserPermission.HOLIDAY_WRITE:
      return 'Feriados (escrita)';
    case UserPermission.APP_USAGE_READ:
      return 'Relatório de uso (leitura)';
    case UserPermission.GRI_READ:
      return 'GRI (leitura)';
    case UserPermission.GRI_WRITE:
      return 'GRI (escrita)';
    case UserPermission.CHANNEL_GROUP_READ:
      return 'Grupos de canais (leitura)';
    case UserPermission.CHANNEL_GROUP_WRITE:
      return 'Grupos de canais (escrita)';
    case UserPermission.PUSH_NOTIFICATIONS_WRITE:
      return 'Notificações (escrita)';
    default:
      return permission;
  }
};
