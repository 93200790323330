import FormProvider from '@/components/forms/hook-form/FormProvider';
import { CloseIcon } from '@/contexts/theme/overrides/CustomIcons';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/redux';
import {
  fetchRmsFlutuations,
  openRmsFlutuationDetails,
  setFluctuationConfigOptions,
} from '@/redux/slices/rmsSlice';
import {
  FluctuationConfigOperation,
  getFluctuationConfigOperationHumanRead,
} from '@/types/enums/rms/fluctuation-config-operation.enum';
import { FluctuationType } from '@/types/enums/rms/fluctuation-type.enum';
import { apiV1RmsService } from '@/utils/api-v1';
import { daysOfWeek, formatDateApi } from '@/utils/dates';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import StepperNavigator from './Navigator';
import FluctuationConfigModalStep1 from './Step1';
import FluctuationConfigModalStep2 from './Step2';
import FluctuationConfigModalStep3 from './Step3';

export type FluctuationConfigFormValuesProps = {
  additionalPrice: string;
  additionalPriceType: FluctuationType;
  additionalFieldRef: 'absolute' | 'suggestedPrice' | 'appliedPrice' | 'customPrice';
  operation: FluctuationConfigOperation;
  weekDays: string[];
};

const FormSchema = Yup.object().shape({
  additionalPrice: Yup.number().required('O valor adicional é obrigatório'),
  additionalPriceType: Yup.string().required('O tipo de valor adicional é obrigatório'),
  additionalFieldRef: Yup.string().required('O campo é obrigatório'),
  weekDays: Yup.array()
    .min(1, 'Pelo menos um dia da semana deve ser selecionado')
    .required('Valor obrigatório'),
});

const defaultValues: FluctuationConfigFormValuesProps = {
  additionalPrice: '',
  additionalPriceType: FluctuationType.FIXED,
  additionalFieldRef: 'absolute',
  operation: FluctuationConfigOperation.CUSTOM_PRICE,
  weekDays: daysOfWeek.map((x) => x._id),
};

export default function FluctuationConfigModal() {
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const { selectedDates, selectedRatePlans, selectedRoomTypes } = useAppSelector(
    (state) => state.rms.fluctuationConfigOptions,
  );
  const { fluctuationDetailsModalOpened } = useAppSelector((state) => state.rms);
  const dispatch = useAppDispatch();
  const { showSuccess } = useNotification();
  const formMethods = useForm<FluctuationConfigFormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
  } = formMethods;

  const values = watch();

  const onClose = () => {
    setActiveStep(0);
    dispatch(
      setFluctuationConfigOptions({
        selectedDates: [],
        selectedRatePlans: [],
        selectedRoomTypes: [],
        selectedFluctuations: [],
      }),
    );
  };

  const onSubmit = async (data: FluctuationConfigFormValuesProps) => {
    try {
      const { weekDays } = data;
      const params = {
        dates: selectedDates
          .filter((x) => weekDays.includes(x.getDay().toString()))
          .map((x) => formatDateApi(x)),
        ratePlans: selectedRatePlans.map((x) => x._id),
        roomTypes: selectedRoomTypes.map((x) => x._id),
        ...data,
      };
      await apiV1RmsService.put(`/fluctuations/batch-config`, params);
      showSuccess('Ação em lote efetuada com sucesso!');
      if (!!fluctuationDetailsModalOpened?.fluctuation?._id) {
        dispatch(openRmsFlutuationDetails(fluctuationDetailsModalOpened?.fluctuation?._id)); // Reload data
      } else if (pathname === '/rms/fluctuation') {
        dispatch(fetchRmsFlutuations());
      }
      onClose();
    } catch {
      //
    }
  };

  const modalOpened = selectedDates.length > 0;

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setActiveStep(1);
    }
  }, [errors]);

  useEffect(() => {
    const removeDuplicates = Array.from(new Set(selectedDates.map((x) => x.getDay())));
    setValue(
      'weekDays',
      removeDuplicates.map((x) => x.toString()),
    );
  }, [selectedDates, setValue]);

  useEffect(() => {
    if (values.additionalFieldRef === 'absolute') {
      setValue('additionalPriceType', FluctuationType.FIXED);
    }
  }, [setValue, values.additionalFieldRef]);

  const nextDisabled =
    activeStep === 1 &&
    values.operation === FluctuationConfigOperation.CUSTOM_PRICE &&
    +values.additionalPrice <= 0;

  return (
    <Dialog
      open={modalOpened}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        style: {
          height: '90vh',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        Alteração em lote{' '}
        {activeStep > 0 ? ` - ${getFluctuationConfigOperationHumanRead(values.operation)}` : ''}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Stepper sx={{ my: 5 }} activeStep={activeStep} alternativeLabel>
          <Step sx={{ cursor: 'pointer' }} onClick={() => setActiveStep(0)}>
            <StepLabel>Selecione a operação</StepLabel>
          </Step>
          <Step sx={{ cursor: 'pointer' }} onClick={() => setActiveStep(1)}>
            <StepLabel>{getFluctuationConfigOperationHumanRead(values.operation)}</StepLabel>
          </Step>
          <Step sx={{ cursor: 'pointer' }} onClick={() => setActiveStep(2)}>
            <StepLabel>Resumo</StepLabel>
          </Step>
        </Stepper>

        <FormProvider methods={formMethods} onSubmit={() => null}>
          {activeStep === 0 ? <FluctuationConfigModalStep1 /> : null}
          {activeStep === 1 ? <FluctuationConfigModalStep2 /> : null}
          {activeStep === 2 ? <FluctuationConfigModalStep3 /> : null}
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid gray' }}>
        <StepperNavigator
          nextDisabled={nextDisabled}
          activeStep={activeStep}
          isSubmitting={isSubmitting}
          onChangeStep={(step) => setActiveStep(step)}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
        />
      </DialogActions>
    </Dialog>
  );
}
