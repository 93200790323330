import {
  DashBenchmarkFilters,
  DashCommonFilters,
  DashConcurrencyFilters,
  DashEmissionFilters,
  DashPickUpFilters,
  DashPickUpOldFilters,
  GriFilters,
} from '@/types/dashboards/dash-filters';
import { ConcurrentPension } from '@/types/enums/concurrency/concurrent-pension.enum';
import { DashGranularity } from '@/types/enums/dashboards/dash-granularity.enum';
import { DashGroup } from '@/types/enums/dashboards/dash-group.enum';
import { DashIndex } from '@/types/enums/dashboards/dash-index.enum';
import { DashPerspective } from '@/types/enums/dashboards/dash-perspective.enum';
import { DashPickUpPerspective } from '@/types/enums/dashboards/pick-up/dash-pick-up-perspective.enum';
import {
  DatePickerPeriod,
  getRangeFromDatepickerPeriod,
} from '@/types/enums/date-picker-period.enum';
import { ReservationStatus } from '@/types/enums/reservation-status.enum';
import { Filters, LogFilters, RmsFilters } from '@/types/general/filters';
import { SavedSearch } from '@/types/models/saved-search';
import { getDefaultFiltersDashConcurrencyValues } from '@/utils/dashboards/concurrency';
import { getDatesArr } from '@/utils/dates';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addMonths, startOfMonth, subDays, subMonths } from 'date-fns';

// Common filters defaults:
const defaultPeriod = DatePickerPeriod.CURRENT_MONTH;
const defaultRange = getRangeFromDatepickerPeriod(defaultPeriod);
const startDate = defaultRange[0];
const endDate = defaultRange[1];

// PickUp filters defaults (OLD):
const defaultPickUpOldPeriod = DatePickerPeriod.PREV_3_DAYS;
const defaultPickUpOldRange = getRangeFromDatepickerPeriod(defaultPickUpOldPeriod);
const defaultPickUpOldPeriodBooking = DatePickerPeriod.CURRENT_MONTH;

// PickUp filters defaults:
const defaultPickUpPeriodEmission = DatePickerPeriod.PREV_3_DAYS;
const defaultPickUpRangeEmission = getRangeFromDatepickerPeriod(defaultPickUpPeriodEmission);
const defaultPickUpPeriodEmissionRitm = DatePickerPeriod.PREV_3_MONTHS;
const defaultPickUpRangeEmissionRitm = getRangeFromDatepickerPeriod(
  defaultPickUpPeriodEmissionRitm,
);
const defaultPickUpPeriodBooking = DatePickerPeriod.NEXT_6_MONTHS;
const defaultDatesBooking = getRangeFromDatepickerPeriod(defaultPickUpPeriodBooking);

// Benchmark filters defaults:
const benchmarkDefaultPeriod = DatePickerPeriod.PREV_MONTH;
const benchmarkDefaultRange = getRangeFromDatepickerPeriod(benchmarkDefaultPeriod);

// Emission filters default:
const emissionDefaultMonth = startOfMonth(new Date());
export const futureMonthsForEmission = 5;
const emissionDefaultBookingPeriods = getDatesArr(
  emissionDefaultMonth,
  addMonths(emissionDefaultMonth, futureMonthsForEmission),
  true,
);

// Concurrency filters default:
const concurrencyDefaultFilters = getDefaultFiltersDashConcurrencyValues();

// Logs filters
const logsDefaultPeriod = DatePickerPeriod.TODAY;
const logsDefaultRange = getRangeFromDatepickerPeriod(logsDefaultPeriod);

// GRI:
const griDefaultBookingPeriod = DatePickerPeriod.NEXT_7_DAYS;
const griDefaultBookingRange = getRangeFromDatepickerPeriod(griDefaultBookingPeriod);

// RMS:
const defaultPeriodSubmission = DatePickerPeriod.CURRENT_YEAR;
const defaultRangeSubmission = getRangeFromDatepickerPeriod(defaultPeriodSubmission);
const defaultStartDateSubmission = defaultRangeSubmission[0];
const defaultEndDateSubmission = defaultRangeSubmission[1];

const initialState: Filters = {
  commonFilters: {
    reloadTrigger: 0,
    period: defaultPeriod,
    startDate,
    endDate,
    perspective: DashPerspective.SUM,
    granularity: DashGranularity.MONTH,
    showGoals: false,
    showSuperGoals: false,
    showLastYear: false,
    showLastMonth: false,
    index: DashIndex.REVENUE,
    indexes: [
      DashIndex.REVENUE,
      DashIndex.ROOM_NIGHT,
      DashIndex.DAILY_AVERAGE,
      DashIndex.OCCUPATION,
    ],
    reservationStatus: ReservationStatus.CONSOLIDATED,
    channels: [],
    channelGroups: [],
    segments: [],
    segmentGroups: [],
    macroSegments: [],
    limitResults: 10,
    groups: [],
    group: DashGroup.HOTELS,
    groupFilterOption: '0',
  },
  pickUpFilters: {
    periodEmission: defaultPickUpPeriodEmission,
    startDateEmission: defaultPickUpRangeEmission[0],
    endDateEmission: defaultPickUpRangeEmission[1],
    periodEmissionRitm: defaultPickUpPeriodEmissionRitm,
    startDateEmissionRitm: defaultPickUpRangeEmissionRitm[0],
    endDateEmissionRitm: defaultPickUpRangeEmissionRitm[1],
    perspective: DashPickUpPerspective.ABSOLUTE,
    granularityEmission: DashGranularity.DAY,
    granularityEmissionRitm: DashGranularity.MONTH,
    granularityBooking: DashGranularity.MONTH,
    periodBooking: defaultPickUpPeriodBooking,
    startDateBooking: defaultDatesBooking[0],
    endDateBooking: defaultDatesBooking[1],
  },
  pickUpOldFilters: {
    // PickUp
    period: defaultPickUpOldPeriod,
    startDate: defaultPickUpOldRange[0],
    endDate: defaultPickUpOldRange[1],
    perspective: DashPickUpPerspective.ABSOLUTE,
    // Ritm
    startDateRitm: startOfMonth(subMonths(new Date(), 3)),
    perspectiveRitm: DashPickUpPerspective.EVOLUTION,
    // General:
    granularity: DashGranularity.MONTH,
    periodBooking: defaultPickUpOldPeriodBooking,
    startDateBooking: defaultDatesBooking[0],
    endDateBooking: defaultDatesBooking[1],
  },
  benchmarkFilters: {
    periodBenchmark: benchmarkDefaultPeriod,
    startDateBenchmark: benchmarkDefaultRange[0],
    endDateBenchmark: benchmarkDefaultRange[1],
  },
  emissionFilters: {
    emissionMonth: emissionDefaultMonth,
    bookingPeriods: emissionDefaultBookingPeriods,
  },
  concurrencyFilters: {
    concurrentHotels: [],
    periodBooking: concurrencyDefaultFilters.periodBooking,
    startDateBooking: concurrencyDefaultFilters.startDateBooking,
    endDateBooking: concurrencyDefaultFilters.endDateBooking,
    occupancy: 0,
    pension: ConcurrentPension.ROOM_ONLY,
    myRoomTypes: [], // Minhas acomodações. Model: ConcurrentRoomType
    refundable: '0', // 0 - qualquer / 1 - sim / 2 - não
  },
  rmsFilters: {
    ratePlans: [], // Visão lista
    ratePlan: null, // Filtro visão calendário
    roomTypes: [], // Visão lista
    roomType: null, // Visão calendário
    fluctuationRules: [], // Visão lista
    fluctuationRule: null, // Visão calendário
    status: 'ALL',
    usingCustomPrice: false,
    filterByDateSubmission: false,
    actionLogModel: 'ALL',
    actionLogItemName: null,
    periodSubmission: defaultPeriodSubmission,
    startDateSubmission: defaultStartDateSubmission,
    endDateSubmission: defaultEndDateSubmission,
  },
  logFilters: {
    periodCreatedAt: logsDefaultPeriod,
    startCreatedAt: logsDefaultRange[0],
    endCreatedAt: logsDefaultRange[1],
    actionLogOperation: 'ALL',
    httpService: '',
    hasError: null,
    requestBody: '',
    responseBody: '',
    url: '',
  },
  griFilters: {
    startDateEmission: subDays(new Date(), 3),
    periodBooking: griDefaultBookingPeriod,
    startDateBooking: griDefaultBookingRange[0],
    endDateBooking: griDefaultBookingRange[1],
  },
  savedSearch: {
    modalOpened: false,
    drawerOpened: false,
    savedSearch: null,
  },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateDashCommonFilters(state, action: PayloadAction<Partial<DashCommonFilters>>) {
      Object.assign(state.commonFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateDashPickUpFilters(state, action: PayloadAction<Partial<DashPickUpFilters>>) {
      Object.assign(state.pickUpFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateDashPickUpOldFilters(state, action: PayloadAction<Partial<DashPickUpOldFilters>>) {
      Object.assign(state.pickUpOldFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateDashEmissionFilters(state, action: PayloadAction<Partial<DashEmissionFilters>>) {
      Object.assign(state.emissionFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateDashBenchmarkFilters(state, action: PayloadAction<Partial<DashBenchmarkFilters>>) {
      Object.assign(state.benchmarkFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateConcurrencyFilters(state, action: PayloadAction<Partial<DashConcurrencyFilters>>) {
      Object.assign(state.concurrencyFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateRmsFilters(state, action: PayloadAction<Partial<RmsFilters>>) {
      Object.assign(state.rmsFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateLogFilters(state, action: PayloadAction<Partial<LogFilters>>) {
      Object.assign(state.logFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    updateGriFilters(state, action: PayloadAction<Partial<GriFilters>>) {
      Object.assign(state.griFilters, action.payload);
      if (!(action as any).payload.skipReloadTrigger) {
        state.commonFilters.reloadTrigger += 1;
      }
    },
    toggleCommonFiltersReloadTrigger(state) {
      // Manually toggling reload trigger to force reload
      state.commonFilters.reloadTrigger += 1;
    },
    toggleSavedSearchModalOpened(
      state,
      action: PayloadAction<{ modalOpened: boolean; savedSearch: SavedSearch | null }>,
    ) {
      const { modalOpened, savedSearch = null } = action.payload;
      state.savedSearch.modalOpened = modalOpened;
      state.savedSearch.savedSearch = savedSearch;
    },
    toggleSavedSearchDrawerOpened(state) {
      state.savedSearch.drawerOpened = !state.savedSearch.drawerOpened;
    },
    applySavedSearchFilters(state, action: PayloadAction<SavedSearch>) {
      const savedSearch = action.payload;
      const appliedFilters = savedSearch.appliedFilters as Filters;
      if (appliedFilters?.commonFilters) {
        Object.assign(state.commonFilters, appliedFilters.commonFilters);
      }

      if (appliedFilters?.pickUpOldFilters) {
        Object.assign(state.pickUpOldFilters, appliedFilters.pickUpOldFilters);
      }

      if (appliedFilters?.pickUpFilters) {
        Object.assign(state.pickUpFilters, appliedFilters.pickUpFilters);
      }

      if (appliedFilters?.benchmarkFilters) {
        Object.assign(state.benchmarkFilters, appliedFilters.benchmarkFilters);
      }

      if (appliedFilters?.emissionFilters) {
        Object.assign(state.emissionFilters, appliedFilters.emissionFilters);
      }

      if (appliedFilters?.concurrencyFilters) {
        Object.assign(state.concurrencyFilters, appliedFilters.concurrencyFilters);
      }

      if (appliedFilters?.rmsFilters) {
        Object.assign(state.rmsFilters, appliedFilters.rmsFilters);
      }

      if (appliedFilters?.griFilters) {
        Object.assign(state.griFilters, appliedFilters.griFilters);
      }

      state.commonFilters.reloadTrigger += 1;
    },
  },
});

export const {
  updateDashCommonFilters,
  updateDashPickUpFilters,
  updateDashPickUpOldFilters,
  updateDashBenchmarkFilters,
  updateDashEmissionFilters,
  updateConcurrencyFilters,
  updateRmsFilters,
  updateLogFilters,
  toggleCommonFiltersReloadTrigger,
  updateGriFilters,
  toggleSavedSearchModalOpened,
  toggleSavedSearchDrawerOpened,
  applySavedSearchFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;
