import { DashboardConcurrencyStatus } from '@/types/enums/dashboards/concurrency/dashboard-concurrency-status.enum';
import {
  DatePickerPeriod,
  getRangeFromDatepickerPeriod,
} from '@/types/enums/date-picker-period.enum';

export function getConcurrencyColorGradientAttribute(status: DashboardConcurrencyStatus) {
  // https://uigradients.com
  let startColor = '#00b09b'; // Default: OK
  let endColor = '#96c93d';

  switch (status) {
    case DashboardConcurrencyStatus.ABOVE:
      startColor = '#ed213a';
      endColor = '#93291e';
      break;
    case DashboardConcurrencyStatus.UNAVAILABLE:
      startColor = '#bdc3c7';
      endColor = '#bdc3aa';
      break;
    case DashboardConcurrencyStatus.BELOW:
      startColor = '#00c6ff';
      endColor = '#0072ff';
      break;
    case DashboardConcurrencyStatus.ERROR:
      startColor = '#f2994a';
      endColor = '#f2c94c';
      break;
    case DashboardConcurrencyStatus.NOT_IMPORTED:
      startColor = '#6a3093';
      endColor = '#a044ff';
      break;
    case DashboardConcurrencyStatus.OK:
    default:
      break;
  }
  return `linear-gradient(${startColor}, ${endColor})`;
}

export const getDefaultFiltersDashConcurrencyValues = () => {
  const defaultPeriod = DatePickerPeriod.NEXT_7_DAYS;
  const defaultRange = getRangeFromDatepickerPeriod(defaultPeriod);

  return {
    periodBooking: defaultPeriod,
    startDateBooking: defaultRange[0],
    endDateBooking: defaultRange[1],
  };
};
