import { apiV1CrudsService, apiV1RmsService } from '@/utils/api-v1';
import { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';

export function useFetchList<T>(url: string, service = 'API_V1_CRUDS') {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<T[]>([]);

  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true);
      try {
        let serviceInstance: AxiosInstance;
        switch (service) {
          case 'API_V1_RMS':
            serviceInstance = apiV1RmsService;
            break;
          case 'API_V1_CRUDS':
          default:
            serviceInstance = apiV1CrudsService;
            break;
        }

        const { data } = await serviceInstance.get(url);
        setResponse(data);
      } catch {
        //
      }
      setLoading(false);
    };

    fetchInfo();
  }, [url, service]);

  return { response, loading };
}
