import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import concurrencySlice from './slices/concurrencySlice';
import dashBenchmarkSlice from './slices/dashboards/dashBenchmarkSlice';
import dashEmissionSlice from './slices/dashboards/dashEmissionSlice';
import dashPickUpOldSlice from './slices/dashboards/dashPickUpOldSlice';
import dashPickUpSlice from './slices/dashboards/dashPickUpSlice';
import dashRankingSlice from './slices/dashboards/dashRankingSlice';
import filtersSlice from './slices/filtersSlice';
import homeSlice from './slices/homeSlice';
import homeV2Slice from './slices/homeV2Slice';
import homeV3Slice from './slices/homeV3Slice';
import legacyDataSlice from './slices/legacyDataSlice';
import logSlice from './slices/logSlice';
import notificationsSlice from './slices/notificationsSlice';
import griSlice from './slices/rms/gri/griSlice';
import rmsSlice from './slices/rmsSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  filters: filtersSlice,
  logs: logSlice,
  home: homeSlice,
  homeV2: homeV2Slice,
  homeV3: homeV3Slice,
  dashRanking: dashRankingSlice,
  dashPickUp: dashPickUpSlice,
  dashPickUpOld: dashPickUpOldSlice,
  dashBenchmark: dashBenchmarkSlice,
  dashEmission: dashEmissionSlice,
  rms: rmsSlice,
  legacyData: legacyDataSlice,
  gri: griSlice,
  concurrency: concurrencySlice,
  notifications: notificationsSlice,
});

export default rootReducer;
