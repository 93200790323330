import { APP_LOADING_ICON } from '@/config/icons';
import { Box } from '@mui/material';
import Iconify from '../Iconify';

interface Props {
  size?: number;
}

export default function LoadingComponent({ size = 48 }: Props) {
  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <Iconify icon={APP_LOADING_ICON} sx={{ width: size, height: size, color: 'primary.main' }} />
    </Box>
  );
}
